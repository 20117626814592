export const Per_Page_Dropdown = async (data) => {
  const a = [];
  for (let i = 5; i <= data; i *= 2) {
    a.push(i);
  }
  a.push(data);
  // console.log(a);

  return a;
};

export const cuppon_type = {
  percentage: "percentage",
  fixedamount: "fixedamount",
};

export const product_coupon = {
  Percentage: "Percentage",
  FixedAmount: "Fixed Amount",
};

export const product_type = {
  B2B: "Bulk Order",
  B2C: "Home Page",
  SampleRoll: "Sample Roll",
};

export const ImageValidation = {
  files_type: ["jpg", "png", "jpeg"],
  product: { w: 578, h: 762 },
};

export const RequiredIs = {
  roles: [1],
  service_term: [1, 2, 3, 4],
  service_type: [1, 2, 3],
  product_type: [1, 2, 3, 4],
};

export const Select2Data = async (data, name, other = false) => {
  const result = data.map((data) => ({
    value: data?.id,
    label: data?.name,
    name: name,
  }));

  if (other) {
    result.push({ value: "0", label: "Other", name: name });
  }
  return result;
};

export const getDimension = async (file) => {
  let reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    reader.onload = () => {
      var image = new Image();
      image.src = reader.result;
      image.onload = function () {
        resolve({ width: this.width, height: this.height });
      };
    };
    reader.readAsDataURL(file);
  });
};

export function formatDate(dateString) {
  const date = new Date(dateString);
  return date.toISOString().split("T")[0];
}

export const ProductNarrationChange = (e) => {
  const { value } = e.target;
  const capitalizedValue = value
    .split(" ")
    .map((word) => word.trim())
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  return capitalizedValue;
};

export const ProductNarrationChangeComa = (e) => {
  const { value } = e.target;
  const capitalizedValue = value
    .split(",")
    .map((word) => word.trim())
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(",");
  return capitalizedValue;
};

export const validateImage = async (file, size = 500) => {
  if (!file) {
    return false;
  }

  return true;
  // if (!file) return "Image is required";

  // Check file size (500KB = 500 * 1024 bytes)
  const maxSizeInBytes = size * 1024;
  if (file.size > maxSizeInBytes) {
    return false;
    // return "Image size must be less than or equal to 500KB";
  }

  return new Promise((resolve) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      const aspectRatio = img.width / img.height;

      if (aspectRatio.toFixed(1) == 0.5) {
        resolve(true);
      } else {
        resolve(false);
        // resolve("Image aspect ratio must be 1:2 (width:height)");
      }
    };
    img.onerror = () => {
      resolve(false);
      // resolve("Invalid image file");
    };
  });
};

export const validateVideo = async (file, size = 5, duration = 40) => {
  return new Promise((resolve) => {
    if (!file) {
      resolve(false);
      return;
    }

    const video = document.createElement("video");
    video.preload = "metadata";

    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      const v_duration = video.duration;
      const v_size = file.size / (1024 * 1024); // size in MB

      if (v_duration > duration) {
        resolve(false);
      } else if (v_size > size) {
        resolve(false);
      } else {
        resolve(true);
      }
    };

    video.src = URL.createObjectURL(file);
  });
};


export const IDS = {
  Role: { List: 1, Add: 2, Edit: 3, Delete: 4 },
  User: { List: 5, Add: 6, Edit: 7, Delete: 8 },
  Country: { List: 9, Add: 10, Edit: 11, Delete: 12 },
  State: { List: 13, Add: 14, Edit: 15, Delete: 16 },
  City: { List: 17, Add: 18, Edit: 19, Delete: 20 },
  Pincode: { List: 21, Add: 22, Edit: 23, Delete: 24 },
  CountryCode: { List: 25, Add: 26, Edit: 27, Delete: 28 },
  Category: { List: 29, Add: 30, Edit: 31, Delete: 32 },
  SubCategory: { List: 33, Add: 34, Edit: 35, Delete: 36 },
  ChildCategory: { List: 37, Add: 38, Edit: 39, Delete: 40 },
  FAQCategory: { List: 41, Add: 42, Edit: 43, Delete: 44 },
  FAQ: { List: 45, Add: 46, Edit: 47, Delete: 48 },
  PopularTags: { List: 49, Add: 50, Edit: 51, Delete: 52 },
  OrderCancelReason: { List: 53, Add: 54, Edit: 55, Delete: 56 },
  OrderReturnReason: { List: 57, Add: 58, Edit: 59, Delete: 60 },
  HelpCategory: { List: 61, Add: 62, Edit: 63, Delete: 64 },
  Help: { List: 65, Add: 66, Edit: 67, Delete: 68 },
  Attribute: { List: 69, Add: 70, Edit: 71, Delete: 72 },
  SubAttribute: { List: 73, Add: 74, Edit: 75, Delete: 76 },
  CouponCodes: { List: 77, Add: 78, Edit: 79, Delete: 80 },
  Brand: { List: 81, Add: 82, Edit: 83, Delete: 84 },
  Blogs: { List: 85, Add: 86, Edit: 87, Delete: 88 },
  TaxType: { List: 89, Add: 90, Edit: 91, Delete: 92 },
  TaxPercentage: { List: 93, Add: 94, Edit: 95, Delete: 96 },
  CategoryBanner: { List: 97, Add: 98, Edit: 99, Delete: 100 },

  // Home Page Permissions
  Banner: { List: 101, Add: 102, Edit: 103, Delete: 104 },
  SideBanner: { List: 105, Add: 106, Edit: 107, Delete: 108 },
  BestEssential: { List: 109, Add: 110, Edit: 111, Delete: 112 },
  TrendingProduct: { List: 113, Add: 114, Edit: 115, Delete: 116 },
  WebHeader: { List: 117, Add: 118, Edit: 119, Delete: 120 },
  AppSetup: { List: 121, Add: 122, Edit: 123, Delete: 124 },
  WhatPeopleSay: { List: 125, Add: 126, Edit: 127, Delete: 128 },

  // Product and Order Permissions
  ProductFeatures: { List: 129, Add: 130, Edit: 131, Delete: 132 },
  Sale: { List: 133, Add: 134, Edit: 135, Delete: 136 },
  Order: { List: 137, Add: 138, Edit: 139, Delete: 140 },
  CancelOrder: { List: 141, Add: 142, Edit: 143, Delete: 144 },
  ReturnOrder: { List: 145, Add: 146, Edit: 147, Delete: 148 },

  // About Us Permissions
  Specifications: { List: 149, Add: 150, Edit: 151, Delete: 152 },
  OurMission: { List: 153, Add: 154, Edit: 155, Delete: 156 },
  SocialMedia: { List: 157, Add: 158, Edit: 159, Delete: 160 },
  Dignitaries: { List: 161, Add: 162, Edit: 163, Delete: 164 },

  // Contact Us Permissions
  Privacy: { List: 165, Add: 166, Edit: 167, Delete: 168 },
  Terms: { List: 169, Add: 170, Edit: 171, Delete: 172 },
  ReturnPolicy: { List: 173, Add: 174, Edit: 175, Delete: 176 },
  Shipping: { List: 177, Add: 178, Edit: 179, Delete: 180 },
  Cancellation: { List: 181, Add: 182, Edit: 183, Delete: 184 },
  PaymentMethod: { List: 185, Add: 186, Edit: 187, Delete: 188 },
  SecurityTrust: { List: 189, Add: 190, Edit: 191, Delete: 192 },
  ContectUs: { List: 193, Add: 194, Edit: 195, Delete: 196 },
  WhyChooseUs: { List: 197, Add: 198, Edit: 199, Delete: 200 },
  WhyCMJ: { List: 201, Add: 202, Edit: 203, Delete: 204 },
  Inquery: { List: 205 },
  CustomizeTap: { List: 206 },
  BulkUpload: {
    Country: 207,
    State: 208,
    City: 209,
    Pincode: 210,
    CountryCode: 211,
    Category: 212,
    SubCategory: 213,
  },
  Product: { List: 214, Add: 215, Edit: 216, Delete: 217 },
  ProductRatings: { List: 218, Add: 219, Edit: 220, Delete: 221 },
};