import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";

import Login from "./Components/Login/Login";
import ForgetPassword from "./Components/Login/LoginPage/ForgetPassword";

// Dashboard

import Dashboard from "./Components/DashBoard/DashBoard";
import Sidebar from "./Components/Sidebar/Sidebar";
import Header from "./Components/Header/Header";
import AdvanceDashboard from "./Components/DashBoard/AdvanceDashboard/AdvanceDashboard2";
// import PrivateDashboard from "./Components/DashBoard/PrivateDashboard/PrivateDashboard";
// import Order from "./Components/DashBoard/Order/Order";

import { Context } from "./utils/context";
import SettingContent from "./Components/Masters/SettingContent/SettingContent";
// import CupponCodes from "./Components/Masters/CupponCodes/Tables";

import SellerDetails from "./Components/sellerDetails/Table";

import Role from "./Components/EmployeeManagement/Role/Table";
import EmployeeDetails from "./Components/EmployeeManagement/EmployeeDetails/Table";
import Employee from "./Components/EmployeeManagement/Employee/Tables";
import EmployeeRequest from "./Components/EmployeeManagement/EmployeeRequests/Table";
import EmployeeRejected from "./Components/EmployeeManagement/EmployeeRejected/Table";
import CustomerDetails from "./Components/EmployeeManagement/CustomerDetails/Table";
import ContactUs from "./Components/CustomerManagement/ContactUSList/Tables";
import QuoteList from "./Components/CustomerManagement/QuoteList/Tables";
import CustomizeTapList from "./Components/CustomerManagement/CustomizeTapList/Tables";

import Product from "./Components/Products/Product/Table";
import ProductFeatures from "./Components/Products/ProductFeatures/Tables";
import Sale from "./Components/Products/Sale/Tables";
import ProductRequests from "./Components/Products/ProductRequests/Table";
import ProductRatings from "./Components/Products/ProductRatings/Tables";

import Specifications from "./Components/AboutUs/Specifications/Tables";
import OurMission from "./Components/AboutUs/OurMission/Tables";
import SocialMedia from "./Components/AboutUs/SocialMedia/Tables";
import Dignitaries from "./Components/AboutUs/Dignitaries/Table";
import HappyCustomers from "./Components/AboutUs/HappyCustomers/Tables";
import OurStory from "./Components/AboutUs/OurStory/Tables";
import AboutBanner from "./Components/AboutUs/AboutBanner/Tables";

import Orders from "./Components/OrderManagments/Orders/Table";
import CancelOrders from "./Components/OrderManagments/CancelOrders/Table";
import OrdersToRefund from "./Components/OrderManagments/OrderToRefund/Table";
import ReturnOrders from "./Components/OrderManagments/ReturnOrders/Table";

// Settings
// Categories
import Category from "./Components/Masters/Category/Tables";
import SubCategory from "./Components/Masters/Sub_Category/Tables";
import ChildSubCategory from "./Components/Masters/Child_Sub_Category/Tables";
import Attributes from "./Components/Masters/Attribute/Tables";
import SubAttributes from "./Components/Masters/SubAttribute/Tables";
// Why
import WhyChooseUs from "./Components/Why/WhyChooseUs/Tables";
import WhyCMJ from "./Components/Why/WhyCMJ/Tables";

// Customization
import WebHeader from "./Components/Masters/WebHeader/Tables";
import AppSetup from "./Components/Masters/AppSetup/Tables";
import HomeBanner from "./Components/Masters/S_Banner/Tables";
import Home_side_banner from "./Components/Masters/Home_side_banner/Tables";
import HomeAdBanner from "./Components/Masters/Ad_Banner/Tables";
import BestEssential from "./Components/Masters/BestEssential/Tables";
import WhatPeopleSay from "./Components/Masters/WhatPeopleSay/Tables";
import Blogs from "./Components/Masters/Blogs/Tables";
import PopularTags from "./Components/Masters/PopularTags/Tables";
import FaqCategory from "./Components/Masters/FaqCategory/Tables";
import Faq from "./Components/Masters/FAQs/Tables";
import HelpCategory from "./Components/Masters/HelpCategory/Tables";
import Help from "./Components/Masters/Help/Tables";
// import OurMission from "./Components/Masters/OurMission/Table";

import CategoryBanner from "./Components/Masters/category_Banner/Tables";
import TrendingProduct from "./Components/Masters/TrendingProduct/Tables";

import Order_cancel_reason from "./Components/Masters/Order_cancel_reason/Tables";
import Order_return_reason from "./Components/Masters/Order_return_reason/Tables";

import SaleReport from "./Components/reports/Sale_report/Tables";
import Inventory_Report from "./Components/reports/inventory_report/Tables";
import Transactions from "./Components/reports/Transactions/Tables";

import CouponCodes from "./Components/Masters/CouponCodes/Table";
import Brands from "./Components/Masters/Brands/Tables";

// Location
import Country from "./Components/Masters/Country/Tables";
import State from "./Components/Masters/State/Tables";
import City from "./Components/Masters/City/Tables";
import Pincode from "./Components/Masters/Pincode/Tables";
import CountryCodes from "./Components/Masters/CountryCode/Tables";

// Tax
import TaxType from "./Components/Masters/Tax_Types/Tables";
import TaxPercentage from "./Components/Masters/Tax_Percentage/Tables";

//Content
import Privacy from "./Components/Content/Privacy/Tables";
import Terms from "./Components/Content/Terms/Tables";
import ReturnPolicy from "./Components/Content/ReturnPolicy/Tables";
import Shipping from "./Components/Content/Shipping/Tables";
import Cancellation from "./Components/Content/Cancellation/Tables";
import PaymentMethod from "./Components/Content/PaymentMethod/Tables";
import SecurityTrust from "./Components/Content/SecurityTrust/Tables";

import { IDS } from "./utils/common";
import PageNotFound from "../src/Components/PageNotFound/PageNotFound";

// Seller

const App = () => {
  const location = useLocation();
  const { isSidebarOpen, isAllow } = useContext(Context);
  const [headerText, setHeaderText] = useState(location.pathname);

  const isLoginPage = location.pathname === "/";
  const isForgetPassword = location.pathname === "/forget-password";

  useEffect(() => {
    setHeaderText(location.pathname);
  });


  const [urls, setUrls] = useState([]);
  useEffect(() => {
    setUrls([
      {
        path: "/",
        element: <Login />,
        status: true,
      },
      {
        path: "/forget-password",
        element: <ForgetPassword />,
        status: true,
      },
      {
        path: "/Header",
        element: <Header />,
        status: true,
      },
      {
        path: "/dashboard",
        element: <Dashboard />,
        status: true,
      },
      {
        path: "/advanceDashboard",
        element: <AdvanceDashboard />,
        status: true,
      },
      {
        path: "/settingContent",
        element: <SettingContent />,
        status: true,
      },
      {
        path: "/employee/role",
        element: <Role />,
        status: isAllow?.includes(IDS.Role.List),
      },

      {
        path: "/seller-detail",
        element: <SellerDetails />,
        status: isAllow?.includes(IDS.User.List),
      },
      {
        path: "/employee/employee-detail",
        element: <Employee />,
        status: isAllow?.includes(IDS.User.List),
      },
      {
        path: "/employee/employee-requests",
        element: <EmployeeRequest />,
        status: isAllow?.includes(IDS.User.List),
      },
      {
        path: "/employee/employee-rejected",
        element: <EmployeeRejected />,
        status: isAllow?.includes(IDS.User.List),
      },
      {
        path: "/employee/customer-details",
        element: <CustomerDetails />,
        status: isAllow?.includes(IDS.User.List),
      },
      {
        path: "/employee/contact-us",
        element: <ContactUs />,
        status: isAllow?.includes(IDS.ContectUs.List),
      },
      {
        path: "/employee/quote",
        element: <QuoteList />,
        // status: isAllow?.includes(IDS.qu.List),
        status: true
      },
      {
        path: "/product",
        element: <Product />,
        status: isAllow?.includes(IDS.Product.List),
      },
      {
        path: "/product-requests",
        element: <ProductRequests />,
        status: isAllow?.includes(IDS.Product.List),
      },
      {
        path: "/product/:id",
        element: <Product />,
        status: isAllow?.includes(IDS.Product.List),
      },
      {
        path: "/product/product-features",
        element: <ProductFeatures />,
        status: isAllow?.includes(IDS.ProductFeatures.List),
      },
      {
        path: "/product/sale",
        element: <Sale />,
        status: isAllow?.includes(IDS.Sale.List),
      },
      {
        path: "/product/ratings",
        element: <ProductRatings />,
        status: isAllow?.includes(IDS.ProductRatings.List),
      },
      {
        path: "/about-us/specifications",
        element: <Specifications />,
        status: isAllow?.includes(IDS.Specifications.List),
      },
      {
        path: "/about-us/our-mission",
        element: <OurMission />,
        status: isAllow?.includes(IDS.OurMission.List),
      },
      {
        path: "/about-us/social-media",
        element: <SocialMedia />,
        status: isAllow?.includes(IDS.SocialMedia.List),
      },
      {
        path: "/about-us/dignitaries",
        element: <Dignitaries />,
        status: isAllow?.includes(IDS.Dignitaries.List),
      },
      {
        path: "/about-us/happy-customers",
        element: <HappyCustomers />,
        // status: isAllow?.includes(IDS.happ.List),
        status: true
      },
      {
        path: "/about-us/our-story",
        element: <OurStory />,
        // status: isAllow?.includes(IDS.our.List),
        status: true
      },
      {
        path: "/about-us/about-banner",
        element: <AboutBanner />,
        // status: isAllow?.includes(IDS.ab.List),
        status: true
      },
      {
        path: "/order-managements/orders",
        element: <Orders />,
        status: isAllow?.includes(IDS.Order.List),
      },
      {
        path: "/order-managements/cancel-orders",
        element: <CancelOrders />,
        status: isAllow?.includes(IDS.CancelOrder.List),
      },
      {
        path: "/order-managements/order/refund",
        element: <OrdersToRefund />,
        status: isAllow?.includes(IDS.Order.List),
      },
      {
        path: "/order-managements/order/return",
        element: <ReturnOrders />,
        status: isAllow?.includes(IDS.ReturnOrder.List),
      },
      {
        path: "/content/privacy",
        element: <Privacy />,
        status: isAllow?.includes(IDS.Privacy.List),
      },
      {
        path: "/content/terms",
        element: <Terms />,
        status: isAllow?.includes(IDS.Terms.List),
      },
      {
        path: "/content/return-policy",
        element: <ReturnPolicy />,
        status: isAllow?.includes(IDS.ReturnPolicy.List),
      },
      {
        path: "/content/shipping",
        element: <Shipping />,
        status: isAllow?.includes(IDS.Shipping.List),
      },
      {
        path: "/content/cancellation",
        element: <Cancellation />,
        status: isAllow?.includes(IDS.Cancellation.List),
      },
      {
        path: "/content/payment-method",
        element: <PaymentMethod />,
        status: isAllow?.includes(IDS.PaymentMethod.List),
      },
      {
        path: "/content/security-trust",
        element: <SecurityTrust />,
        status: isAllow?.includes(IDS.SecurityTrust.List),
      },
      ///////
      {
        path: "/settings/order-cancel-reason",
        element: <Order_cancel_reason />,
        status: isAllow?.includes(IDS.OrderCancelReason.List),
      },
      {
        path: "/settings/order-return-reason",
        element: <Order_return_reason />,
        status: isAllow?.includes(IDS.OrderReturnReason.List),
      },
      {
        path: "/settings/category",
        element: <Category />,
        status: isAllow?.includes(IDS.Category.List),
      },
      {
        path: "/settings/sub-category",
        element: <SubCategory />,
        status: isAllow?.includes(IDS.SubCategory.List),
      },
      {
        path: "/settings/child-sub-category",
        element: <ChildSubCategory />,
        status: isAllow?.includes(IDS.ChildCategory.List),
      },
      {
        path: "/settings/attribute",
        element: <Attributes />,
        status: isAllow?.includes(IDS.Attribute.List),
      },
      {
        path: "/settings/sub-attribute",
        element: <SubAttributes />,
        status: isAllow?.includes(IDS.SubAttribute.List),
      },
      {
        path: "/why/why-choose-us",
        element: <WhyChooseUs />,
        status: isAllow?.includes(IDS.WhyChooseUs.List),
      },
      {
        path: "/why/why-cmj",
        element: <WhyCMJ />,
        status: isAllow?.includes(IDS.WhyCMJ.List),
      },
      {
        path: "/settings/customization/web-header",
        element: <WebHeader />,
        status: isAllow?.includes(IDS.WebHeader.List),
      },
      {
        path: "/settings/customization/trending-product",
        element: <TrendingProduct />,
        status: isAllow?.includes(IDS.TrendingProduct.List),
      },
      {
        path: "/settings/customization/app-setup",
        element: <AppSetup />,
        status: isAllow?.includes(IDS.AppSetup.List),
      },
      {
        path: "/settings/customization/home-banner",
        element: <HomeBanner />,
        status: isAllow?.includes(IDS.Banner.List),
      },
      {
        path: "/settings/customization/home-ad-banner",
        element: <HomeAdBanner />,
        // status: isAllow?.includes(IDS..List),
        status: true
      },
      {
        path: "/settings/customization/best-essential",
        element: <BestEssential />,
        status: isAllow?.includes(IDS.BestEssential.List),
      },
      {
        path: "/settings/customization/what-people-say",
        element: <WhatPeopleSay />,
        status: isAllow?.includes(IDS.WhatPeopleSay.List),
      },
      {
        path: "/settings/customization/category-banner",
        element: <CategoryBanner />,
        status: isAllow?.includes(IDS.CategoryBanner.List),
      },
      {
        path: "/settings/customization/blogs",
        element: <Blogs />,
        status: isAllow?.includes(IDS.Blogs.List),
      },
      {
        path: "/settings/customization/popular-tags",
        element: <PopularTags />,
        status: isAllow?.includes(IDS.PopularTags.List),
      },
      {
        path: "/settings/customization/faq-category",
        element: <FaqCategory />,
        status: isAllow?.includes(IDS.FAQCategory.List),
      },
      {
        path: "/settings/customization/faqs",
        element: <Faq />,
        status: isAllow?.includes(IDS.FAQ.List),
      },
      {
        path: "/settings/customization/help",
        element: <Help />,
        status: isAllow?.includes(IDS.Help.List),
      },
      {
        path: "/settings/customization/help-category",
        element: <HelpCategory />,
        status: isAllow?.includes(IDS.HelpCategory.List),
      },
      {
        path: "/settings/Coupon-codes",
        element: <CouponCodes />,
        status: isAllow?.includes(IDS.CouponCodes.List),
      },
      {
        path: "/settings/brands",
        element: <Brands />,
        status: isAllow?.includes(IDS.Brand.List),
      },
      {
        path: "/settings/location/country",
        element: <Country />,
        status: isAllow?.includes(IDS.Country.List),
      },
      {
        path: "/settings/location/state",
        element: <State />,
        status: isAllow?.includes(IDS.State.List),
      },
      {
        path: "/settings/location/city",
        element: <City />,
        status: isAllow?.includes(IDS.City.List),
      },
      {
        path: "/settings/location/pincode",
        element: <Pincode />,
        status: isAllow?.includes(IDS.Pincode.List),
      },
      {
        path: "/settings/location/country-codes",
        element: <CountryCodes />,
        status: isAllow?.includes(IDS.CountryCode.List),
      },
      {
        path: "/settings/tax/tax-type",
        element: <TaxType />,
        status: isAllow?.includes(IDS.TaxType.List),
      },
      {
        path: "/reports/sales",
        element: <SaleReport />,
        status: true,
      },
      {
        path: "/reports/inventory",
        element: <Inventory_Report />,
        status: true,
      },
      {
        path: "/reports/transactions",
        element: <Transactions />,
        status: true,
      },
      {
        path: "/settings/tax/tax-percentage",
        element: <TaxPercentage />,
        status: isAllow?.includes(IDS.TaxPercentage.List),
      },


    ])
  }, [isAllow]);

  return (
    <div
      className={`main ${isSidebarOpen ? "sidebar-open" : "sidebar-closed"}`}
    >
      {isLoginPage ? (
        <Login />
      ) : isForgetPassword ? (
        <ForgetPassword />
      ) : (
        <div className=" d-flex sidebar-x-scroll">
          <div
            className={`${isSidebarOpen
              ? "sidebarHeadopen "
              : "scale-in-hor-left sidebarHeadClose"
              }  pe-0`}
          >
            <Sidebar />
          </div>
          <div
            className={`${isSidebarOpen
              ? "dashboradopen"
              : "scale-in-hor-right dashboradopen"
              }  ps-0`}
          >
            <div className="allRoutesMain">
              <Routes>
                {urls?.map(
                  (url) =>
                    url.status && (
                      <Route path={url.path} element={url.element} />
                    )
                )}
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </div>
            {/* <Home /> */}
          </div>
        </div >
      )}
    </div >
  );
};

export default App;
