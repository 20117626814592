import React, { useState, handlelick, active, useContext } from "react";
import "./Sidebar.css";
import { Link, useLocation } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import Accordion from "react-bootstrap/Accordion";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { Context } from "../../utils/context";
import { faAffiliatetheme } from "@fortawesome/free-brands-svg-icons";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";

import { IDS } from "../../utils/common";

library.add(fas);

const Sidebar = () => {
  const { isSidebarOpen, setSidebarOpen, isAllow } = useContext(Context);
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(true);
  const [showItemNames, setShowItemNames] = useState(true);
  const [headerText, setHeaderText] = useState(location.pathname);

  useEffect(() => {
    setHeaderText(location.pathname);
  });

  const [active, setActive] = useState("0");
  const handleClick = (eventKey) => {
    if (active === eventKey) {
      setActive(null);
    } else {
      setActive(eventKey);
      // setSidebarOpen(true);
    }
  };

  const isActive = (path) => {
    return location.pathname.includes(path) ? "active block" : "none";
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <section className="sidebar">
        <div className="desktop-view-sidebar">
          <div className="top_section">
            <img
              className="logo ms-2"
              // src={process.env.PUBLIC_URL + "/netpurtilogo.png"}
              src={process.env.PUBLIC_URL + "/cmj_logo.png"}
            />
            {/* <h1>Net Purti</h1> */}
          </div>
          <ul className="list-unstyled ps-0">
            {/* ***************************************************Dashboard*************************************************************** */}

            <Accordion
              // defaultActiveKey="0"
              className="mb-1"
              data-bs-toggle="collapse"
              data-bs-target="#home-collapse"
              aria-expanded="false"
            >
              <Accordion.Item
                eventKey="0"
                className="mb-1"
                onClick={() => handleClick("0")}
              >
                <Accordion.Header>
                  <div className=" me-3">
                    <FontAwesomeIcon
                      icon="fa-solid fa-house"
                      onClick={() => setIsOpen(!isOpen)}
                    />
                  </div>
                  <p
                    className="sidebar_txt"
                    style={{ display: isSidebarOpen ? "block" : "none" }}
                    onClick={() => setSidebarOpen(true)}
                  >
                    Dashboard
                  </p>

                  {/* </button> */}
                </Accordion.Header>
                <Accordion.Body className="p-0">
                  <ul
                    className={`accordion_list list-unstyled fw-normal pb-1 small ${isSidebarOpen ? "arrowshow" : "arrowHide"
                      }`}
                    style={{
                      display: isSidebarOpen ? "block" : "none",
                    }}
                  >
                    <Link
                      to="/advanceDashboard"
                      className="text-decoration-none"
                    >
                      <li className={isActive("/advanceDashboard")}>
                        <p key={2} className="insideDropdown_list" id={"2"}>
                          Advance Dashboard
                        </p>
                      </li>
                    </Link>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              {/* *********************************************************Dashboard Ends*************************************************************** */}

              {isAllow.includes(IDS.Role.List) ||
                isAllow.includes(IDS.User.List) ? (
                <Accordion.Item
                  eventKey="15"
                  className="mb-1"
                  onClick={() => handleClick("15")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa-solid fa-sliders"
                      // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Employee managements
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                    // style={{
                    //   display: isSidebarOpen ? "block" : "none",
                    // }}
                    >

                      {isAllow.includes(IDS.Role.List) ||
                        isAllow.includes(IDS.Role.Add) ||
                        isAllow.includes(IDS.Role.Edit) ||
                        isAllow.includes(IDS.Role.Delete) ? (
                        <Link
                          to="/employee/role"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                  "/employee/role"
                                )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              User Roles
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow.includes(IDS.User.List) ||
                        isAllow.includes(IDS.User.Add) ||
                        isAllow.includes(IDS.User.Edit) ||
                        isAllow.includes(IDS.User.Delete) ? (
                        <Link
                          to="/employee/employee-detail"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                  "/employee/employee-detail"
                                )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Employee
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}

              {/* ************************************************************   Content Start ************************************************************* */}

              <Accordion.Item
                eventKey="20"
                className="mb-1"
                onClick={() => handleClick("20")}
              >
                <Accordion.Header>
                  <div className="me-3">
                    <FontAwesomeIcon
                      icon="fa-solid fa-gear"
                    // onClick={() => setIsOpen(!isOpen)}
                    />
                  </div>
                  <p
                    className="sidebar_txt"
                    style={{ display: isSidebarOpen ? "block" : "none" }}
                  >
                    Settings
                  </p>
                </Accordion.Header>
                <Accordion.Body
                  className="p-0"
                  style={{
                    display: isOpen ? "block" : "none",
                  }}
                >
                  <ul
                    className="accordion_list list-unstyled fw-normal pb-1 small"
                  // style={{
                  //   display: isSidebarOpen ? "block" : "none",
                  // }}
                  >
                    {/* <Link
                      to="/settings/customization/faq-category"
                      className=" text-decoration-none  "
                      // className="text-decoration-none rounded "
                    >
                      <li
                        className={
                          isOpen
                            ? headerText.includes(
                                "/settings/customization/faq-category"
                              )
                              ? "active block"
                              : "none"
                            : "none"
                        }
                      >
                        <p
                          className="insideDropdown_list"
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Faq Category
                        </p>
                      </li>
                    </Link> */}

                    {isAllow.includes(IDS.FAQ.List) ||
                      isAllow.includes(IDS.FAQ.Add) ||
                      isAllow.includes(IDS.FAQ.Edit) ||
                      isAllow.includes(IDS.FAQ.Delete) ? (
                      < Link
                        to="/settings/customization/faqs"
                        className=" text-decoration-none  "
                      // className="text-decoration-none rounded "
                      >
                        <li
                          className={
                            isOpen
                              ? headerText.includes(
                                "/settings/customization/faqs"
                              )
                                ? "active block"
                                : "none"
                              : "none"
                          }
                        >
                          <p
                            className="insideDropdown_list"
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            FAQ
                          </p>
                        </li>
                      </Link>
                    ) : (
                      <></>
                    )}
                    {isAllow.includes(IDS.HelpCategory.List) ||
                      isAllow.includes(IDS.HelpCategory.Add) ||
                      isAllow.includes(IDS.HelpCategory.Edit) ||
                      isAllow.includes(IDS.HelpCategory.Delete) ? (
                      <Link
                        to="/settings/customization/help-category"
                        className=" text-decoration-none  "
                      // className="text-decoration-none rounded "
                      >
                        <li
                          className={
                            isOpen
                              ? headerText.includes(
                                "/settings/customization/help-category"
                              )
                                ? "active block"
                                : "none"
                              : "none"
                          }
                        >
                          <p
                            className="insideDropdown_list"
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Help Category
                          </p>
                        </li>
                      </Link>
                    ) : (
                      <></>
                    )}

                    {isAllow.includes(IDS.Help.List) ||
                      isAllow.includes(IDS.Help.Add) ||
                      isAllow.includes(IDS.Help.Edit) ||
                      isAllow.includes(IDS.Help.Delete) ? (
                      <Link
                        to="/settings/customization/help"
                        className=" text-decoration-none  "
                      // className="text-decoration-none rounded "
                      >
                        <li
                          className={
                            isOpen
                              ? headerText.includes(
                                "/settings/customization/help"
                              )
                                ? "active block"
                                : "none"
                              : "none"
                          }
                        >
                          <p
                            className="insideDropdown_list"
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Help
                          </p>
                        </li>
                      </Link>
                    ) : (
                      <></>
                    )}

                    {isAllow.includes(IDS.OrderCancelReason.List) ||
                      isAllow.includes(IDS.OrderCancelReason.Add) ||
                      isAllow.includes(IDS.OrderCancelReason.Edit) ||
                      isAllow.includes(IDS.OrderCancelReason.Delete) ? (
                      <Link
                        to="/settings/order-cancel-reason"
                        className=" text-decoration-none  "
                      // className="text-decoration-none rounded "
                      >
                        <li
                          className={
                            isOpen
                              ? headerText.includes(
                                "/settings/order-cancel-reason"
                              )
                                ? "active block"
                                : "none"
                              : "none"
                          }
                        >
                          <p
                            className="insideDropdown_list"
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Order Cancel Reason
                          </p>
                        </li>
                      </Link>
                    ) : (
                      <></>
                    )}

                    {isAllow.includes(IDS.OrderReturnReason.List) ||
                      isAllow.includes(IDS.OrderReturnReason.Add) ||
                      isAllow.includes(IDS.OrderReturnReason.Edit) ||
                      isAllow.includes(IDS.OrderReturnReason.Delete) ? (
                      <Link
                        to="/settings/order-return-reason"
                        className=" text-decoration-none  "
                      // className="text-decoration-none rounded "
                      >
                        <li
                          className={
                            isOpen
                              ? headerText.includes(
                                "/settings/order-return-reason"
                              )
                                ? "active block"
                                : "none"
                              : "none"
                          }
                        >
                          <p
                            className="insideDropdown_list"
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Order Return Reason
                          </p>
                        </li>
                      </Link>
                    ) : (
                      <></>
                    )}

                    {/* {isAllow.includes(IDS.Terms.List) ||
                      isAllow.includes(IDS.Terms.Add) ||
                      isAllow.includes(IDS.Terms.Edit) ||
                      isAllow.includes(IDS.Terms.Delete) ? (
                      <Link
                        to="/content/terms"
                        className=" text-decoration-none  "
                      // className="text-decoration-none rounded "
                      >
                        <li
                          className={
                            isOpen
                              ? headerText.includes("/content/terms")
                                ? "active block"
                                : "none"
                              : "none"
                          }
                        >
                          <p
                            className="insideDropdown_list"
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Terms & Conditions
                          </p>
                        </li>
                      </Link>
                    ) : (
                      <></>
                    )} */}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>

              {/* ************************************************************  Settings End ************************************************************* */}

              {/* ************************************************************  Categories ************************************************************* */}
              {isAllow.includes(IDS.Category.List) ||
                isAllow.includes(IDS.SubCategory.List) ||
                isAllow.includes(IDS.ChildCategory.List) ? (
                <Accordion.Item
                  eventKey="2"
                  className="mb-1"
                  onClick={() => handleClick("2")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon icon="fa fa-bullseye" />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Categories
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul className="accordion_list list-unstyled fw-normal pb-1 small">
                      {isAllow.includes(IDS.Category.List) ||
                        isAllow.includes(IDS.Category.Add) ||
                        isAllow.includes(IDS.Category.Edit) ||
                        isAllow.includes(IDS.Category.Delete) ? (
                        <Link
                          to="/settings/category"
                          className=" text-decoration-none  "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/settings/category")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Category
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow.includes(IDS.SubCategory.List) ||
                        isAllow.includes(IDS.SubCategory.Add) ||
                        isAllow.includes(IDS.SubCategory.Edit) ||
                        isAllow.includes(IDS.SubCategory.Delete) ? (
                        <Link
                          to="/settings/sub-category"
                          className=" text-decoration-none  "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/settings/sub-category")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Sub Category
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow.includes(IDS.SubCategory.List) ||
                        isAllow.includes(IDS.SubCategory.Add) ||
                        isAllow.includes(IDS.SubCategory.Edit) ||
                        isAllow.includes(IDS.SubCategory.Delete) ? (
                        <Link
                          to="/settings/child-sub-category"
                          className=" text-decoration-none  "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                  "/settings/child-sub-category"
                                )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Child Sub Category
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}
              {/* ************************************************************  Categories End ************************************************************* */}

              {/* ************************************************************  Home Settings  ************************************************************* */}
              {isAllow.includes(IDS.Banner.List) ||
                isAllow.includes(IDS.WhatPeopleSay.List) ? (
                <Accordion.Item
                  eventKey="9"
                  className="mb-1"
                  onClick={() => handleClick("9")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa-solid fa-house-laptop"

                      // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Home Settings
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                    // style={{
                    //   display: isSidebarOpen ? "block" : "none",
                    // }}
                    >
                      {isAllow.includes(IDS.Banner.List) ||
                        isAllow.includes(IDS.Banner.Add) ||
                        isAllow.includes(IDS.Banner.Edit) ||
                        isAllow.includes(IDS.Banner.Delete) ? (
                        <Link
                          to="/settings/customization/home-banner"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                  "/settings/customization/home-banner"
                                )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Home Banner
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {/* <Link
                        to="/settings/customization/home-side-banner"
                        className=" text-decoration-none  "
                      // className="text-decoration-none rounded "
                      >
                        <li
                          className={
                            isOpen
                              ? headerText.includes(
                                "/settings/customization/home-side-banner"
                              )
                                ? "active block"
                                : "none"
                              : "none"
                          }
                        >
                          <p
                            className="insideDropdown_list"
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Home Side Banner
                          </p>
                        </li>
                      </Link> */}

                      {/* <Link
                        to="/settings/customization/trending-product"
                        className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                      >
                        <li
                          className={
                            isOpen
                              ? headerText.includes(
                                  "/settings/customization/trending-product"
                                )
                                ? "active block"
                                : "none"
                              : "none"
                          }
                        >
                          <p
                            className="insideDropdown_list"
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Trending Product
                          </p>
                        </li>
                      </Link> */}

                      {/* <Link
                        to="/settings/customization/best-essential"
                        className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                      >
                        <li
                          className={
                            isOpen
                              ? headerText.includes(
                                  "/settings/customization/best-essential"
                                )
                                ? "active block"
                                : "none"
                              : "none"
                          }
                        >
                          <p
                            className="insideDropdown_list"
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Best Essential
                          </p>
                        </li>
                      </Link> */}

                      {isAllow.includes(IDS.WhatPeopleSay.List) ||
                        isAllow.includes(IDS.WhatPeopleSay.Add) ||
                        isAllow.includes(IDS.WhatPeopleSay.Edit) ||
                        isAllow.includes(IDS.WhatPeopleSay.Delete) ? (
                        <Link
                          to="/settings/customization/what-people-say"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                  "/settings/customization/what-people-say"
                                )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              What People Say
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {/* <Link
                        to="/settings/customization/home-ad-banner"
                        className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                      >
                        <li
                          className={
                            isOpen
                              ? headerText.includes(
                                  "/settings/customization/home-ad-banner"
                                )
                                ? "active block"
                                : "none"
                              : "none"
                          }
                        >
                          <p
                            className="insideDropdown_list"
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Advertisement Banner
                          </p>
                        </li>
                      </Link> */}

                      {/* <Link
                        to="/settings/customization/category-banner"
                        className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                      >
                        <li
                          className={
                            isOpen
                              ? headerText.includes(
                                  "/settings/customization/category-banner"
                                )
                                ? "active block"
                                : "none"
                              : "none"
                          }
                        >
                          <p
                            className="insideDropdown_list"
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Category Banner
                          </p>
                        </li>
                      </Link> */}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}
              {/* ************************************************************   Home Settings End ************************************************************* */}

              {/* ************************************************************  Blogs  ************************************************************* */}
              {/* {isAllow?.includes(65) ? (
                <Accordion.Item
                  eventKey="10"
                  className="mb-1"
                  onClick={() => handleClick("10")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa-solid fa-blog"

                        // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Blogs
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                      // style={{
                      //   display: isSidebarOpen ? "block" : "none",
                      // }}
                    >
                      <Link
                        to="/settings/customization/popular-tags"
                        className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                      >
                        <li
                          className={
                            isOpen
                              ? headerText.includes(
                                  "/settings/customization/popular-tags"
                                )
                                ? "active block"
                                : "none"
                              : "none"
                          }
                        >
                          <p
                            className="insideDropdown_list"
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Popular Tags
                          </p>
                        </li>
                      </Link>

                      <Link
                        to="/settings/customization/blogs"
                        className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                      >
                        <li
                          className={
                            isOpen
                              ? headerText.includes(
                                  "/settings/customization/blogs"
                                )
                                ? "active block"
                                : "none"
                              : "none"
                          }
                        >
                          <p
                            className="insideDropdown_list"
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Blogs
                          </p>
                        </li>
                      </Link>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )} */}
              {/* ************************************************************   Blogs End ************************************************************* */}

              {/* ************************************************************  Orders ************************************************************* */}

              {isAllow.includes(IDS.Order.List) ||
                isAllow.includes(IDS.ReturnOrder.List) ||
                isAllow.includes(IDS.ProductRatings.List) ||
                isAllow.includes(IDS.CancelOrder.List) ? (
                <Accordion.Item
                  eventKey="1"
                  className="mb-1"
                  onClick={() => handleClick("1")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon icon="fa fa-shopping-cart" />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Orders
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul className="accordion_list list-unstyled fw-normal pb-1 small">

                      {isAllow.includes(IDS.Order.List) ||
                        isAllow.includes(IDS.Order.Add) ||
                        isAllow.includes(IDS.Order.Edit) ||
                        isAllow.includes(IDS.Order.Delete) ? (
                        <Link
                          to="/order-managements/orders"
                          className=" text-decoration-none  "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/order-managements/orders")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Order
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {/* {isAllow.includes(IDS.ReturnOrder.List) ||
                        isAllow.includes(IDS.ReturnOrder.Add) ||
                        isAllow.includes(IDS.ReturnOrder.Edit) ||
                        isAllow.includes(IDS.ReturnOrder.Delete) ? (
                        <Link
                          to="/order-managements/order/return"
                          className=" text-decoration-none  "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                  "/order-managements/order/return"
                                )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Returned Order
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow.includes(IDS.CancelOrder.List) ||
                        isAllow.includes(IDS.CancelOrder.Add) ||
                        isAllow.includes(IDS.CancelOrder.Edit) ||
                        isAllow.includes(IDS.CancelOrder.Delete) ? (
                        <Link
                          to="/order-managements/cancel-orders"
                          className=" text-decoration-none  "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                  "/order-managements/cancel-orders"
                                )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Canceled Orders
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )} */}

                      {isAllow.includes(IDS.ProductRatings.List) ||
                        isAllow.includes(IDS.ProductRatings.Edit) ||
                        isAllow.includes(IDS.ProductRatings.Delete) ? (
                        <Link
                          to="/product/ratings"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/product/ratings")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Ratings
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {/* <Link
                      to="/order-managements/order/refund"
                      className=" text-decoration-none  "
                    >
                      <li
                        className={
                          isOpen
                            ? headerText.includes(
                                "/order-managements/order/refund"
                              )
                              ? "active block"
                              : "none"
                            : "none"
                        }
                      >
                        <p
                          className="insideDropdown_list"
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Order To Refund
                        </p>
                      </li>
                    </Link> */}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}

              {/* ************************************************************  Orders End ************************************************************* */}

              {/* ************************************************************  Products ************************************************************* */}

              {isAllow.includes(IDS.Product.List) ||
                isAllow.includes(IDS.ProductFeatures.List) ||
                isAllow.includes(IDS.Sale.List) ? (
                <Accordion.Item
                  eventKey="3"
                  className="mb-1"
                  onClick={() => handleClick("3")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa fa-cubes"
                      // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Products
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                    // style={{
                    //   display: isSidebarOpen ? "block" : "none",
                    // }}
                    >
                      {isAllow.includes(IDS.ProductFeatures.List) ||
                        isAllow.includes(IDS.ProductFeatures.Add) ||
                        isAllow.includes(IDS.ProductFeatures.Edit) ||
                        isAllow.includes(IDS.ProductFeatures.Delete) ? (
                        <Link
                          to="/product/product-features"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/product/product-features")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Product Features
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow.includes(IDS.Product.List) ||
                        isAllow.includes(IDS.Product.Add) ||
                        isAllow.includes(IDS.Product.Edit) ||
                        isAllow.includes(IDS.Product.Delete) ? (
                        <Link
                          to="product"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/product")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Product
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow.includes(IDS.Sale.List) ||
                        isAllow.includes(IDS.Sale.Add) ||
                        isAllow.includes(IDS.Sale.Edit) ||
                        isAllow.includes(IDS.Sale.Delete) ? (
                        <Link
                          to="/product/sale"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/product/sale")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Sale
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}



                      {/* {isAllow?.includes(5) ||
                    isAllow?.includes(6) ||
                    isAllow?.includes(7) ||
                    isAllow?.includes(8) ? (
                      <Link
                        to="/product-requests"
                        className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                      >
                        <li
                          className={
                            isOpen
                              ? headerText.includes("/product-requests")
                                ? "active block"
                                : "none"
                              : "none"
                          }
                        >
                          <p
                            className="insideDropdown_list"
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Product Requests
                          </p>
                        </li>
                      </Link>
                    ) : (
                      <></>
                    )} */}

                      {/* <Link
                      to="/settings/tax/tax-type"
                      className=" text-decoration-none  "
                      // className="text-decoration-none rounded "
                    >
                      <li
                        className={
                          isOpen
                            ? headerText.includes("/settings/tax/tax-type")
                              ? "active block"
                              : "none"
                            : "none"
                        }
                      >
                        <p
                          className="insideDropdown_list"
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Tax Types
                        </p>
                      </li>
                    </Link> */}

                      {/* <Link
                      to="/settings/tax/tax-percentage"
                      className=" text-decoration-none  "
                      // className="text-decoration-none rounded "
                    >
                      <li
                        className={
                          isOpen
                            ? headerText.includes(
                                "/settings/tax/tax-percentage"
                              )
                              ? "active block"
                              : "none"
                            : "none"
                        }
                      >
                        <p
                          className="insideDropdown_list"
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Tax Percentage
                        </p>
                      </li> 
                    </Link>*/}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}

              {/* ************************************************************  Products End ************************************************************* */}

              {/* ************************************************************  Sellers ************************************************************* */}
              {/* {isAllow?.includes(5) ||
              isAllow?.includes(6) ||
              isAllow?.includes(7) ||
              isAllow?.includes(8) ? (
                <Accordion.Item
                  eventKey="4"
                  className="mb-1"
                  onClick={() => handleClick("4")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa fa-store"
                        // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Sellers
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                      // style={{
                      //   display: isSidebarOpen ? "block" : "none",
                      // }}
                    >
                      <Link
                        to="/employee/employee-requests"
                        className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                      >
                        <li
                          className={
                            isOpen
                              ? headerText.includes(
                                  "/employee/employee-requests"
                                )
                                ? "active block"
                                : "none"
                              : "none"
                          }
                        >
                          <p
                            className="insideDropdown_list"
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Seller Request
                          </p>
                        </li>
                      </Link>

                     
                      <Link
                        to="/seller-detail"
                        className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                      >
                        <li
                          className={
                            isOpen
                              ? headerText.includes("/seller-detail")
                                ? "active block"
                                : "none"
                              : "none"
                          }
                        >
                          <p
                            className="insideDropdown_list"
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Sellers Details
                          </p>
                        </li>
                      </Link>

                      <Link
                        to="/employee/employee-rejected"
                        className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                      >
                        <li
                          className={
                            isOpen
                              ? headerText.includes(
                                  "/employee/employee-rejected"
                                )
                                ? "active block"
                                : "none"
                              : "none"
                          }
                        >
                          <p
                            className="insideDropdown_list"
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Rejected Requests
                          </p>
                        </li>
                      </Link>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )} */}

              {/* ************************************************************  Sellers End ************************************************************* */}
              {/* ************************************************************  Customers ************************************************************* */}
              {isAllow.includes(IDS.User.List) ||
                isAllow.includes(IDS.ContectUs.List) ? (
                <Accordion.Item
                  eventKey="18"
                  className="mb-1"
                  onClick={() => handleClick("18")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa fa-users"
                      // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Customers
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                    // style={{
                    //   display: isSidebarOpen ? "block" : "none",
                    // }}
                    >
                      {isAllow.includes(IDS.User.List) ||
                        isAllow.includes(IDS.User.Add) ||
                        isAllow.includes(IDS.User.Edit) ||
                        isAllow.includes(IDS.User.Delete) ? (
                        <Link
                          to="/employee/customer-details"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                  "/employee/customer-details"
                                )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Customers Details
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow.includes(IDS.ContectUs.List) ||
                        isAllow.includes(IDS.ContectUs.Add) ||
                        isAllow.includes(IDS.ContectUs.Edit) ||
                        isAllow.includes(IDS.ContectUs.Delete) ? (
                        <Link
                          to="/employee/contact-us"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/employee/contact-us")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Contact Us
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      <Link
                        to="/employee/quote"
                        className=" text-decoration-none  "
                      // className="text-decoration-none rounded "
                      >
                        <li
                          className={
                            isOpen
                              ? headerText.includes("/employee/quote")
                                ? "active block"
                                : "none"
                              : "none"
                          }
                        >
                          <p
                            className="insideDropdown_list"
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Inquiry
                          </p>
                        </li>
                      </Link>

                      <Link
                        to="/employee/customize-tap"
                        className=" text-decoration-none  "
                      // className="text-decoration-none rounded "
                      >
                        <li
                          className={
                            isOpen
                              ? headerText.includes("/employee/customize-tap")
                                ? "active block"
                                : "none"
                              : "none"
                          }
                        >
                          <p
                            className="insideDropdown_list"
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Customize Tape List
                          </p>
                        </li>
                      </Link>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}

              {/* ************************************************************  Customers End ************************************************************* */}

              {/* ************************************************************  Customization ************************************************************* */}
              {isAllow.includes(IDS.AppSetup.List) ||
                isAllow.includes(IDS.WebHeader.Add) ? (
                <Accordion.Item
                  eventKey="5"
                  className="mb-1"
                  onClick={() => handleClick("5")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa-solid fa-sliders"
                      // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Customization
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                    // style={{
                    //   display: isSidebarOpen ? "block" : "none",
                    // }}
                    >
                      {/* {isAllow.includes(IDS.WebHeader.List) ||
                        isAllow.includes(IDS.WebHeader.Add) ||
                        isAllow.includes(IDS.WebHeader.Edit) ||
                        isAllow.includes(IDS.WebHeader.Delete) ? (
                        <Link
                          to="/settings/customization/web-header"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                  "/settings/customization/web-header"
                                )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Web Header
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )} */}

                      {isAllow.includes(IDS.AppSetup.List) ||
                        isAllow.includes(IDS.AppSetup.Add) ||
                        isAllow.includes(IDS.AppSetup.Edit) ||
                        isAllow.includes(IDS.AppSetup.Delete) ? (
                        <Link
                          to="/settings/customization/app-setup"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                  "/settings/customization/app-setup"
                                )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              App Setup
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}
              {/* ************************************************************  Customization End ************************************************************* */}

              {/* ************************************************************  Coupon Codes  ************************************************************* */}

              {isAllow.includes(IDS.CouponCodes.List) ? (
                <Accordion.Item
                  eventKey="6"
                  className="mb-1"
                  onClick={() => handleClick("6")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa-solid fa-ticket"

                      // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Coupon Codes
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                    // style={{
                    //   display: isSidebarOpen ? "block" : "none",
                    // }}
                    >
                      {isAllow.includes(IDS.CouponCodes.List) ||
                        isAllow.includes(IDS.CouponCodes.Add) ||
                        isAllow.includes(IDS.CouponCodes.Edit) ||
                        isAllow.includes(IDS.CouponCodes.Delete) ? (
                        <Link
                          to="/settings/Coupon-codes"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/settings/Coupon-codes")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Coupon Codes
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}

              {/* ************************************************************   Coupon Codes End ************************************************************* */}

              {/* ************************************************************  Customers ************************************************************* */}

              {/* <Accordion.Item
              eventKey="7"
              className="mb-1"
              onClick={() => handleClick("7")}
            >
              <Accordion.Header>
                <div className="me-3">
                  <FontAwesomeIcon
                    icon="fa fa-male"
                  // onClick={() => setIsOpen(!isOpen)}
                  />
                </div>
                <p
                  className="sidebar_txt"
                  style={{ display: isSidebarOpen ? "block" : "none" }}
                >
                  Customers
                </p>
              </Accordion.Header>
              <Accordion.Body
                className="p-0"
                style={{
                  display: isOpen ? "block" : "none",
                }}
              >
                <ul
                  className="accordion_list list-unstyled fw-normal pb-1 small"
                // style={{
                //   display: isSidebarOpen ? "block" : "none",
                // }}
                >
                  <Link
                    to="product"
                    className=" text-decoration-none  "
                  // className="text-decoration-none rounded "
                  >
                    <li
                      className={
                        isOpen
                          ? headerText.includes("/product")
                            ? "active block"
                            : "none"
                          : "none"
                      }
                    >
                      <p
                        className="insideDropdown_list"
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Product
                      </p>
                    </li>
                  </Link>




                </ul>
              </Accordion.Body>

            </Accordion.Item> */}

              {/* ************************************************************  Customers End ************************************************************* */}

              {/* ************************************************************  Location ************************************************************* */}
              {isAllow.includes(IDS.Country.List) ||
                isAllow.includes(IDS.State.List) ||
                isAllow.includes(IDS.City.List) ||
                isAllow.includes(IDS.Pincode.List) ? (
                <Accordion.Item
                  eventKey="8"
                  className="mb-1"
                  onClick={() => handleClick("8")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa-solid fa-map-location-dot"

                      // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Location
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                    // style={{
                    //   display: isSidebarOpen ? "block" : "none",
                    // }}
                    >

                      {isAllow.includes(IDS.Country.List) ||
                        isAllow.includes(IDS.Country.Add) ||
                        isAllow.includes(IDS.Country.Edit) ||
                        isAllow.includes(IDS.Country.Delete) ? (
                        <Link
                          to="/settings/location/country"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                  "/settings/location/country"
                                )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Country
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow.includes(IDS.State.List) ||
                        isAllow.includes(IDS.State.Add) ||
                        isAllow.includes(IDS.State.Edit) ||
                        isAllow.includes(IDS.State.Delete) ? (
                        <Link
                          to="/settings/location/state"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/settings/location/state")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              State
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow.includes(IDS.City.List) ||
                        isAllow.includes(IDS.City.Add) ||
                        isAllow.includes(IDS.City.Edit) ||
                        isAllow.includes(IDS.City.Delete) ? (
                        <Link
                          to="/settings/location/city"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/settings/location/city")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              City
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow.includes(IDS.Pincode.List) ||
                        isAllow.includes(IDS.Pincode.Add) ||
                        isAllow.includes(IDS.Pincode.Edit) ||
                        isAllow.includes(IDS.Pincode.Delete) ? (
                        <Link
                          to="/settings/location/pincode"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                  "/settings/location/pincode"
                                )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Pincode
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}
              {/* ************************************************************  Location End ************************************************************* */}

              {/* ************************************************************  Brands  ************************************************************* */}
              {/* {isAllow?.includes(49) ? (
                <Accordion.Item
                  eventKey="11"
                  className="mb-1"
                  onClick={() => handleClick("11")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa-solid fa-copyright"

                        // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Brands
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                      // style={{
                      //   display: isSidebarOpen ? "block" : "none",
                      // }}
                    >
                      <Link
                        to="/settings/brands"
                        className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                      >
                        <li
                          className={
                            isOpen
                              ? headerText.includes("/settings/brands")
                                ? "active block"
                                : "none"
                              : "none"
                          }
                        >
                          <p
                            className="insideDropdown_list"
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Brands
                          </p>
                        </li>
                      </Link>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )} */}
              {/* ************************************************************   Brands End ************************************************************* */}

              {/* ************************************************************  Tax  ************************************************************* */}
              {/* {isAllow?.includes(53) ||
                isAllow?.includes(57) ? (
                <Accordion.Item
                  eventKey="12"
                  className="mb-1"
                  onClick={() => handleClick("12")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa-solid fa-money-check-dollar"

                      // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Tax
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                    // style={{
                    //   display: isSidebarOpen ? "block" : "none",
                    // }}
                    >
                      <Link
                        to="/settings/tax/tax-type"
                        className=" text-decoration-none  "
                      // className="text-decoration-none rounded "
                      >
                        <li
                          className={
                            isOpen
                              ? headerText.includes("/settings/tax/tax-type")
                                ? "active block"
                                : "none"
                              : "none"
                          }
                        >
                          <p
                            className="insideDropdown_list"
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Tax Types
                          </p>
                        </li>
                      </Link>

                      <Link
                        to="/settings/tax/tax-percentage"
                        className=" text-decoration-none  "
                      // className="text-decoration-none rounded "
                      >
                        <li
                          className={
                            isOpen
                              ? headerText.includes("/settings/tax/tax-percentage")
                                ? "active block"
                                : "none"
                              : "none"
                          }
                        >
                          <p
                            className="insideDropdown_list"
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Tax Percentage
                          </p>
                        </li>
                      </Link>

                    </ul>
                  </Accordion.Body>

                </Accordion.Item>
              ) : (
                <></>
              )} */}
              {/* ************************************************************   Tax End ************************************************************* */}

              {/* ************************************************************  Attributes  ************************************************************* */}

              {isAllow.includes(IDS.Attribute.List) ||
                isAllow.includes(IDS.SubAttribute.List) ? (
                <Accordion.Item
                  eventKey="13"
                  className="mb-1"
                  onClick={() => handleClick("13")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa-solid fa-mattress-pillow"

                      // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Attributes
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                    // style={{
                    //   display: isSidebarOpen ? "block" : "none",
                    // }}
                    >

                      {isAllow.includes(IDS.Attribute.List) ||
                        isAllow.includes(IDS.Attribute.Add) ||
                        isAllow.includes(IDS.Attribute.Edit) ||
                        isAllow.includes(IDS.Attribute.Delete) ? (
                        <Link
                          to="/settings/attribute"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/settings/attribute")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Attribute
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow.includes(IDS.SubAttribute.List) ||
                        isAllow.includes(IDS.SubAttribute.Add) ||
                        isAllow.includes(IDS.SubAttribute.Edit) ||
                        isAllow.includes(IDS.SubAttribute.Delete) ? (
                        <Link
                          to="/settings/sub-attribute"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/settings/sub-attribute")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Sub Attribute
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}

              {/* ************************************************************   Attributes End ************************************************************* */}

              {/* ************************************************************  Why  Section  ************************************************************* */}
              {isAllow.includes(IDS.WhyChooseUs.List) ||
                isAllow.includes(IDS.WhyCMJ.Add) ||
                isAllow.includes(IDS.OurMission.Edit) ? (
                <Accordion.Item
                  eventKey="14"
                  className="mb-1"
                  onClick={() => handleClick("14")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        // icon="fa-regular fa-circle-question"
                        icon="fa-solid fa-circle-question"

                      // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Why Section
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                    // style={{
                    //   display: isSidebarOpen ? "block" : "none",
                    // }}
                    >
                      {" "}

                      {isAllow.includes(IDS.WhyChooseUs.List) ||
                        isAllow.includes(IDS.WhyChooseUs.Add) ||
                        isAllow.includes(IDS.WhyChooseUs.Edit) ||
                        isAllow.includes(IDS.WhyChooseUs.Delete) ? (
                        <Link
                          to="/why/why-choose-us"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/why/why-choose-us")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Why Choose Us
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow.includes(IDS.WhyCMJ.List) ||
                        isAllow.includes(IDS.WhyCMJ.Add) ||
                        isAllow.includes(IDS.WhyCMJ.Edit) ||
                        isAllow.includes(IDS.WhyCMJ.Delete) ? (
                        <Link
                          to="/why/why-cmj"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/why/why-cmj")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Why CMJ
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow.includes(IDS.OurMission.List) ||
                        isAllow.includes(IDS.OurMission.Add) ||
                        isAllow.includes(IDS.OurMission.Edit) ||
                        isAllow.includes(IDS.OurMission.Delete) ? (
                        <Link
                          to="/about-us/our-mission"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/about-us/our-mission")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Our Vission Mission
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}
              {/* ************************************************************   Why End ************************************************************* */}

              {/* ************************************************************   About Us Start ************************************************************* */}

              {isAllow.includes(IDS.SocialMedia.List) ? (
                <Accordion.Item
                  eventKey="17"
                  className="mb-1"
                  onClick={() => handleClick("17")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa-solid fa-gear"
                      // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      About Us
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                    // style={{
                    //   display: isSidebarOpen ? "block" : "none",
                    // }}
                    >
                      {isAllow.includes(IDS.SocialMedia.List) ||
                        isAllow.includes(IDS.SocialMedia.Add) ||
                        isAllow.includes(IDS.SocialMedia.Edit) ||
                        isAllow.includes(IDS.SocialMedia.Delete) ? (

                        <Link
                          to="/about-us/social-media"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/about-us/social-media")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Social Media
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}
              {/* ************************************************************   About Us End ************************************************************* */}
              {/* ************************************************************   Content Start ************************************************************* */}

              {isAllow.includes(IDS.Privacy.List) ||
                isAllow.includes(IDS.Terms.Add) ||
                isAllow.includes(IDS.ReturnPolicy.Edit) ||
                isAllow.includes(IDS.Cancellation.Edit) ||
                isAllow.includes(IDS.PaymentMethod.Edit) ||
                isAllow.includes(IDS.SecurityTrust.Edit) ||
                isAllow.includes(IDS.Shipping.Delete) ? (
                <Accordion.Item
                  eventKey="19"
                  className="mb-1"
                  onClick={() => handleClick("19")}
                >
                  <Accordion.Header>
                    <div className="me-3">
                      <FontAwesomeIcon
                        icon="fa-solid fa-gear"
                      // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Policy Content
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                    // style={{
                    //   display: isSidebarOpen ? "block" : "none",
                    // }}
                    >

                      {isAllow.includes(IDS.Privacy.List) ||
                        isAllow.includes(IDS.Privacy.Add) ||
                        isAllow.includes(IDS.Privacy.Edit) ||
                        isAllow.includes(IDS.Privacy.Delete) ? (
                        <Link
                          to="/content/privacy"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/content/privacy")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Privacy Policy
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow.includes(IDS.Terms.List) ||
                        isAllow.includes(IDS.Terms.Add) ||
                        isAllow.includes(IDS.Terms.Edit) ||
                        isAllow.includes(IDS.Terms.Delete) ? (
                        <Link
                          to="/content/terms"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/content/terms")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Terms & Conditions
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow.includes(IDS.ReturnPolicy.List) ||
                        isAllow.includes(IDS.ReturnPolicy.Add) ||
                        isAllow.includes(IDS.ReturnPolicy.Edit) ||
                        isAllow.includes(IDS.ReturnPolicy.Delete) ? (
                        <Link
                          to="/content/return-policy"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/content/return-policy")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Return Policy
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow.includes(IDS.Shipping.List) ||
                        isAllow.includes(IDS.Shipping.Add) ||
                        isAllow.includes(IDS.Shipping.Edit) ||
                        isAllow.includes(IDS.Shipping.Delete) ? (
                        <Link
                          to="/content/shipping"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/content/shipping")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Shipping Policy
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow.includes(IDS.Cancellation.List) ||
                        isAllow.includes(IDS.Cancellation.Add) ||
                        isAllow.includes(IDS.Cancellation.Edit) ||
                        isAllow.includes(IDS.Cancellation.Delete) ? (
                        <Link
                          to="/content/cancellation"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/content/cancellation")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Cancellation Policy
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow.includes(IDS.PaymentMethod.List) ||
                        isAllow.includes(IDS.PaymentMethod.Add) ||
                        isAllow.includes(IDS.PaymentMethod.Edit) ||
                        isAllow.includes(IDS.PaymentMethod.Delete) ? (
                        <Link
                          to="/content/payment-method"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/content/payment-method")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Payment Method Policy
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      {isAllow.includes(IDS.SecurityTrust.List) ||
                        isAllow.includes(IDS.SecurityTrust.Add) ||
                        isAllow.includes(IDS.SecurityTrust.Edit) ||
                        isAllow.includes(IDS.SecurityTrust.Delete) ? (
                        <Link
                          to="/content/security-trust"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/content/security-trust")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Security Trust Policy
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}

              {/* ************************************************************  Content End ************************************************************* */}


              <Accordion.Item
                eventKey="21"
                className="mb-1"
                onClick={() => handleClick("21")}
              >
                <Accordion.Header>
                  <div className="me-3">
                    <FontAwesomeIcon
                      icon="fa-solid fa-gear"
                    // onClick={() => setIsOpen(!isOpen)}
                    />
                  </div>
                  <p
                    className="sidebar_txt"
                    style={{ display: isSidebarOpen ? "block" : "none" }}
                  >
                    Reports
                  </p>
                </Accordion.Header>
                <Accordion.Body
                  className="p-0"
                  style={{
                    display: isOpen ? "block" : "none",
                  }}
                >
                  <ul
                    className="accordion_list list-unstyled fw-normal pb-1 small"
                  // style={{
                  //   display: isSidebarOpen ? "block" : "none",
                  // }}
                  >


                    <Link
                      to="/reports/sales"
                      className=" text-decoration-none  "
                    // className="text-decoration-none rounded "
                    >
                      <li
                        className={
                          isOpen
                            ? headerText.includes("/reports/sales")
                              ? "active block"
                              : "none"
                            : "none"
                        }
                      >
                        <p
                          className="insideDropdown_list"
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          sales
                        </p>
                      </li>
                    </Link>

                    <Link
                      to="/reports/inventory"
                      className=" text-decoration-none  "
                    // className="text-decoration-none rounded "
                    >
                      <li
                        className={
                          isOpen
                            ? headerText.includes("/reports/inventory")
                              ? "active block"
                              : "none"
                            : "none"
                        }
                      >
                        <p
                          className="insideDropdown_list"
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Inventory
                        </p>
                      </li>
                    </Link>

                    <Link
                      to="/reports/transactions"
                      className=" text-decoration-none  "
                    // className="text-decoration-none rounded "
                    >
                      <li
                        className={
                          isOpen
                            ? headerText.includes("/reports/transactions")
                              ? "active block"
                              : "none"
                            : "none"
                        }
                      >
                        <p
                          className="insideDropdown_list"
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Transaction History
                        </p>
                      </li>
                    </Link>


                  </ul>
                </Accordion.Body>
              </Accordion.Item>

            </Accordion>
          </ul>
        </div>
      </section >
    </>
  );
};

export default Sidebar;
