import React, { useContext, useEffect } from "react";
import { useState } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

// *******************toster****************************
import ModalSave from "../../common/ModelSave";
import { CancelButton, SaveButton } from "../../common/Button";

import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import JoditEditor from "jodit-react";
import { Select2Data } from "../../../utils/common";
import Select from "react-select";
library.add(fas);

const AddOffCanvance = (props) => {
  const { postData, getData } = useContext(Context);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const [roles, setRoles] = useState([]);
  const GetAllRoles = async () => {
    const response = await getData("/allroles");
    if (response?.success) {
      setRoles(await Select2Data(response?.data, "role_id"));
    }
  };

  useEffect(() => {
    GetAllRoles();
  }, []);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
  } = useForm();

  const imageFile = watch("image");

  const onSubmit = async (data) => {
    try {
      const sendData = new FormData();
      sendData.append("role_id", data?.role_id?.value);
      sendData.append("first_name", data?.first_name);
      sendData.append("last_name", data?.last_name);
      sendData.append("contact_no", data?.contact_no);
      sendData.append("email", data?.email);
      sendData.append("password", data?.password);

      const response = await postData(`/employee/employee-details`, sendData);

      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({
          code: response?.code,
          message: response?.message,
        });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Add Employee</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
            <Row className="justify-content-center">
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Role</Form.Label>
                    <Controller
                      name="role_id" // name of the field
                      {...register("role_id", {
                        required: "Select Role",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.role_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={roles}
                        />
                      )}
                    />
                    {errors.role_id && (
                      <span className="text-danger">
                        {errors.role_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>
              {/* <Col md={6} /> */}
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Form.Group>
                    <Form.Label>First Name</Form.Label>

                    <InputGroup>
                      <Form.Control
                        type="text"
                        name="first_name"
                        placeholder="First name"
                        className={classNames("", {
                          "is-invalid": errors?.first_name,
                        })}
                        {...register("first_name", {
                          required: "First Name is required",
                        })}
                      />
                    </InputGroup>
                    {errors.first_name && (
                      <span className="text-danger">{errors.first_name.message}</span>
                    )}
                  </Form.Group>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Form.Group>
                    <Form.Label>Last Name</Form.Label>

                    <InputGroup>
                      <Form.Control
                        type="text"
                        name="last_name"
                        placeholder="Last name"
                        className={classNames("", {
                          "is-invalid": errors?.last_name,
                        })}
                        {...register("last_name", {
                          required: "Last Name is required",
                        })}
                      />
                    </InputGroup>
                    {errors.last_name && (
                      <span className="text-danger">{errors.last_name.message}</span>
                    )}
                  </Form.Group>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Form.Group>
                    <Form.Label>Contact No</Form.Label>

                    <InputGroup>
                      <Form.Control
                        type="text"
                        name="contact_no"
                        placeholder="Contact No"
                        className={classNames("", {
                          "is-invalid": errors?.contact_no,
                        })}
                        {...register("contact_no", {
                          required: "Contact No is required",
                        })}
                      />
                    </InputGroup>
                    {errors.contact_no && (
                      <span className="text-danger">{errors.contact_no.message}</span>
                    )}
                  </Form.Group>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Form.Group>
                    <Form.Label>Login Email</Form.Label>

                    <InputGroup>
                      <Form.Control
                        type="text"
                        name="email"
                        placeholder="Login Email"
                        className={classNames("", {
                          "is-invalid": errors?.email,
                        })}
                        {...register("email", {
                          required: "Login Email is required",
                        })}
                      />
                    </InputGroup>
                    {errors.email && (
                      <span className="text-danger">{errors.email.message}</span>
                    )}
                  </Form.Group>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Form.Group>
                    <Form.Label>Login Password</Form.Label>

                    <InputGroup>
                      <Form.Control
                        type="text"
                        name="password"
                        placeholder="Login Password"
                        className={classNames("", {
                          "is-invalid": errors?.password,
                        })}
                        {...register("password", {
                          required: "Login Password is required",
                        })}
                      />
                    </InputGroup>
                    {errors.password && (
                      <span className="text-danger">{errors.password.message}</span>
                    )}
                  </Form.Group>
                </div>
              </Col>

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
