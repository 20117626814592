import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  InputGroup,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import classNames from "classnames";
import JoditEditor from "jodit-react";
import Table from "react-bootstrap/Table";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ValidationImage } from "../../../../utils/api";
import { ImageValidation, product_coupon } from "../../../../utils/common";
import { CancelButton } from "../../../common/Button";
import { Context } from "../../../../utils/context";
import { Features, Pincode } from "../../../../utils/apis/master/Master";

import uploadImage from "./Image/uploadImage.jpg";
import DeleteImage from "./Image/dltt.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination, Autoplay } from "swiper/modules";
import Successfull_Modal from "../../../common/Successfull_Modal/Successfull_Modal";

function Step1({ handleNext, handlePrevious, OffcanvasCloseButton, ...props }) {
  const { postData, getData, Select2Data, getDimension, IMG_URL } =
    useContext(Context);

  const [product_features, setProduct_features] = useState([]);
  const [available_pincodes, setavailable_pincodes] = useState([]);
  const [ProductType, setProductType] = useState([
    {
      name: "product_type",
      label: "Bulk Order",
      value: "Bulk Order",
    },
    {
      name: "product_type",
      label: "Home Page",
      value: "Home Page",
    },
    {
      name: "product_type",
      label: "Sample Roll",
      value: "Sample Roll",
    },
  ]);
  const [categories, setCategories] = useState([]);
  const [units, setUnits] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [childCategories, setchildCategories] = useState([]);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    watch,
    setValue,
    reset,
    clearErrors,
  } = useForm({
    defaultValues: {
      product_min_order_qty: [],
    },
  });

  const imageFile = watch("image");

  const uploadimages = watch("uploadimages");

  const { fields, append, remove } = useFieldArray({
    control,
    name: "product_min_order_qty",
  });

  // const { fields, append, remove } = useFieldArray({
  //   control,
  //   name: "quantity",
  // });

  // const [quantityError, setquantityError] = useState("");

  // useEffect(() => {
  //   register("quantity", {
  //     validate: (value) => {
  //       const isValid = value && value.length > 0;
  //       setquantityError(isValid ? "" : "At least one Quantity is required");
  //       return isValid;
  //     },
  //   });
  // }, [register]);

  const validateSellingPrice = (value, index) => {
    const mrpValue = parseFloat(getValues(`quantity.${index}.mrp`));
    const sellingPrice = parseFloat(value);
    if (sellingPrice >= mrpValue) {
      return "Selling price must be less than MRP";
    }
    return true; // Validation passed
  };

  // Step 3 start

  const [uploadDefaultImages, setUploadDefaultImages] = useState([
    {
      img: uploadImage,
      dltimg: DeleteImage,
      selectedImage: null,
      type: null,
      status: false,
    },
    {
      img: uploadImage,
      dltimg: DeleteImage,
      selectedImage: null,
      type: null,
      status: false,
    },
    {
      img: uploadImage,
      dltimg: DeleteImage,
      selectedImage: null,
      type: null,
      status: false,
    },
    {
      img: uploadImage,
      dltimg: DeleteImage,
      selectedImage: null,
      type: null,
      status: false,
    },
    {
      img: uploadImage,
      dltimg: DeleteImage,
      selectedImage: null,
      type: null,
      status: false,
    },
    {
      img: uploadImage,
      dltimg: DeleteImage,
      selectedImage: null,
      type: null,
      status: false,
    },
    {
      img: uploadImage,
      dltimg: DeleteImage,
      selectedImage: null,
      type: null,
      status: false,
    },
    {
      img: uploadImage,
      dltimg: DeleteImage,
      selectedImage: null,
      type: null,
      status: false,
    },
  ]);

  const getMediaType = (fileType) => {
    return fileType.startsWith("image/") ? "image" : "video";
  };

  const handleProductImage = async (e, index) => {
    const file = e.target.files[0];

    await setValue(`uploadimages[${index}].img`, uploadImage);
    await setValue(`uploadimages[${index}].dltimg`, DeleteImage);
    await setValue(
      `uploadimages[${index}].selectedImage`,
      URL.createObjectURL(file)
    );
    await setValue(`uploadimages[${index}].type`, getMediaType(file.type));
    await setValue(`uploadimages[${index}].status`, true);

    reset(getValues());
  };

  const removeProductImage = async (index) => {
    const fileInput = document.getElementById("product_image_" + index);
    fileInput.value = "";

    await setValue(`uploadimages[${index}].img`, uploadImage);
    await setValue(`uploadimages[${index}].dltimg`, DeleteImage);
    await setValue(`uploadimages[${index}].selectedImage`, null);
    await setValue(`uploadimages[${index}].status`, false);
    await setValue(`uploadimages[${index}].path`, null);
    await setValue(`uploadimages[${index}].image_id`, 0);

    reset(getValues());
  };

  // Step 3 End

  const [successModal, setSuccessModal] = useState(false);

  // Coupon Codes
  const [selectedType, setSelectedType] = useState("");

  const onSubmit = async (data) => {
    try {
      // return;
      const DataToSend = new FormData();

      if (props?.product_id) {
        DataToSend.append("id", props?.product_id);
      }

      {
        DataToSend.append("is_cod", data?.is_cod);
        DataToSend.append("is_moq", data?.is_moq);
        DataToSend.append("is_returnable", data?.is_returnable);
        DataToSend.append("is_refundable", data?.is_refundable);
        DataToSend.append("is_cancellable", data?.is_cancellable);
        DataToSend.append("brand", data?.brand);
        DataToSend.append("product_type", data?.product_type?.value);
        DataToSend.append("generic_keywords", data?.generic_keywords);
        DataToSend.append("min_quantity", data?.min_quantity);
      }

      DataToSend.append("tax_percentage", data?.tax_percentage);
      DataToSend.append("box_unit", data?.box_unit);

      DataToSend.append("category_id", data?.category_id?.value);

      if (data?.sub_category_id) {
        DataToSend.append("sub_category_id", data?.sub_category_id?.value);
      }

      if (data?.child_category_id) {
        DataToSend.append("child_category_id", data?.child_category_id?.value);
      }

      DataToSend.append("name", data?.name);
      DataToSend.append("short_description", data?.short_description);

      DataToSend.append("image", data?.image[0]);
      DataToSend.append("image_src", data?.image);

      DataToSend.append("fast_delivery_days", data?.fast_delivery_days);
      DataToSend.append("free_delivery_days", data?.free_delivery_days);
      DataToSend.append("extra_charges", data?.extra_charges);
      DataToSend.append("matarial", data?.matarial);
      DataToSend.append("sold_by", data?.sold_by);

      // Descriptions
      DataToSend.append("about", data?.about);
      DataToSend.append("product_description", data?.product_description);
      DataToSend.append("product_info", data?.product_info);

      // Multiple Values
      DataToSend.append(
        "product_features",
        JSON.stringify(data?.product_features)
      );
      DataToSend.append(
        "available_pincodes",
        JSON.stringify(data?.available_pincodes)
      );

      DataToSend.append(
        "product_min_order_qty",
        JSON.stringify(data?.product_min_order_qty)
      );

      // step 3 Start

      DataToSend.append("stock", data.stock);
      DataToSend.append("mrp", data.mrp);
      DataToSend.append("btb_price", data.btb_price);
      DataToSend.append("btc_price", data.btc_price);
      DataToSend.append("sku_id", data.sku_id);
      DataToSend.append("hsn_code", data.hsn_code);

      //
      DataToSend.append("color", data.color);
      DataToSend.append("width", data.width);
      DataToSend.append("length", data.length);
      DataToSend.append("thickness", data.thickness);
      DataToSend.append("weight", data.weight);

      let count = 0;

      DataToSend.append("start", count);
      data?.uploadimages?.forEach((image, imageIndex) => {
        if (image.status) {
          var file = document.getElementById(`product_image_${imageIndex}`);
          if (file.files[0]) {
            DataToSend.append("image" + count, file.files[0]);
          }
          count++;
        }
      });
      DataToSend.append("end", count);
      DataToSend.append("uploadimages", JSON.stringify(data.uploadimages));

      // Step 3 END

      // Coupons Start

      DataToSend.append("coupon_name", data?.coupon_name);
      DataToSend.append("coupon_code", data?.coupon_code);
      DataToSend.append("coupon_type", selectedType);
      if (data?.coupon_type === product_coupon.Percentage) {
        DataToSend.append("discount", data?.discount);
      } else {
        DataToSend.append("total_amount", data?.discount);
      }

      // Coupons End

      const response = await postData(`/product`, DataToSend);
      // console.log("response", response);
      // console.log("data to send", DataToSend);

      if (response?.success) {
        setSuccessModal(true);
        setTimeout(() => {
          setSuccessModal(false);
          OffcanvasCloseButton();
        }, 2000);

        // props.setProduct_Id(response?.data?.id);
        // handleNext();
      }
      // if (response?.success) {
      //   await setShowModal({
      //     code: response?.code,
      //     message: response?.message,
      //   });
      // } else {
      //   await setShowModal({
      //     code: response?.code,
      //     message: response?.message,
      //   });
      // }
      // setTimeout(() => {
      //   setShowModal(0);
      //   props.handleClose();
      // }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const GetAllCategory = async () => {
    {
      const response = await getData("/allcategories");

      if (response?.success) {
        setCategories(await Select2Data(response?.data, "category_id"));
      }
    }

    {
      const response = await getData("/all-units");

      if (response?.success) {
        setUnits(await Select2Data(response?.data, "unit_id"));
      }
    }
  };

  const GetAllSubCategory = async (id) => {
    const response = await getData(`/allsubcategories/${id}`);

    if (response?.success) {
      setSubCategories(await Select2Data(response?.data, "sub_category_id"));
    }
  };

  const GetAllChildSubCategory = async (id) => {
    const response = await getData(`/allchildcategories/${id}`);

    if (response?.success) {
      setchildCategories(await Select2Data(response?.data, "sub_category_id"));
    }
  };

  const GetAllFeatures = async () => {
    {
      const response = await Features();

      if (response?.success) {
        setProduct_features(
          await Select2Data(response?.data, "product_features")
        );
      }
    }
  };

  const GetAllPincodes = async (search = "") => {
    {
      const response = await Pincode(search);

      if (response?.success) {
        setavailable_pincodes(
          await Select2Data(response?.data, "available_pincodes")
        );
      }
    }
  };

  // const GetAllBrands = async () => {
  //   const response = await getData("/allbrands");

  //   if (response?.success) {
  //     setBrands(await Select2Data(response?.data, "brand_id"));
  //   }
  // };

  // const GetAllTaxTypes = async () => {
  //   const response = await getData("/all-tax-type");

  //   if (response?.success) {
  //     setTaxTypes(await Select2Data(response?.data, "tax_type_id"));
  //   }
  // };

  // const GetAllTaxPercentages = async (id) => {
  //   const response = await getData(`/all-tax-percentage/${id}`);

  //   if (response?.success) {
  //     setPercentages(await Select2Data(response?.data, "tax_percentage_id"));
  //   }
  // };

  // const GetAllAttributes = async () => {
  //   const response = await getData(`/all-attribute`);

  //   if (response?.success) {
  //     setAttribute(await Select2Data(response?.data, "attribute_id"));
  //   }
  // };

  // const GetAllSubAttributes = async (id) => {
  //   const response = await getData(`/all-sub-attribute/${id}`);

  //   if (response?.success) {
  //     setSubAttribute(await Select2Data(response?.data, "sub_attribute_id"));
  //   }
  // };

  useEffect(() => {
    GetAllCategory();
    GetAllFeatures();
    GetAllPincodes();
    // GetAllBrands();
    // GetAllTaxTypes();
    // GetAllAttributes();
  }, []);

  // const deleteImage = async (index) => {
  //   const updatedImagesFile = [...imagesFile];
  //   updatedImagesFile.splice(index, 1);
  //   setValue("images", updatedImagesFile);
  // };

  // Edit Data
  const GetEditData = async () => {
    const res = await getData(`/product/${props.product_id}`);

    reset(res?.data);

    setSelectedType(res?.data?.coupon_type);
    if (res?.data?.coupon_type === product_coupon.Percentage) {
      setValue("discount", res?.data?.discount);
    } else {
      setValue("discount", res?.data?.total_amount);
    }

    if (res.data.variant_images) {
      const images = [];
      res.data.variant_images.forEach((image, imageIndex) => {
        images.push({
          img: uploadImage,
          dltimg: DeleteImage,
          selectedImage: IMG_URL + image.image,
          path: image.image,
          type: image.type,
          status: true,
          image_id: image.id,
        });
      });
      for (let i = res.data.variant_images.length; i < 8; i++) {
        images.push({
          img: uploadImage,
          dltimg: DeleteImage,
          selectedImage: null,
          type: null,
          status: false,
        });
      }
      setValue(`uploadimages`, images);
    } else {
      // setSelectedImage("");
      // setValue(`description`, "");
      setValue(`uploadimages`, uploadDefaultImages);
    }
  };

  useEffect(() => {
    if (props.product_id) {
      GetEditData();
    } else {
      setValue(`uploadimages`, uploadDefaultImages);
    }
  }, [props.product_id]);
  return (
    <>
      <fieldset>
        <Form
          // onSubmit={() => handleSubmit(onSubmit)}
          role="form"
        // className="stateclass"
        >
          <Row>
            <Col md={3}>
              <div className="main-form-section mt-3">
                <Row className="row justify-content-center mb-2">
                  <Form.Label>Product Type</Form.Label>
                  <Controller
                    name="product_type"
                    {...register("product_type", {
                      required: "Select Product Type",
                    })}
                    control={control}
                    render={({ field }) => (
                      <Select
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors.product_type
                              ? "red"
                              : baseStyles,
                          }),
                        }}
                        {...field}
                        options={ProductType}
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption.value); // Update Controller's value
                          setValue("product_type", selectedOption);
                          reset(getValues());
                        }}
                      />
                    )}
                  />
                  {errors.product_type && (
                    <span className="text-danger">
                      {errors.product_type.message}
                    </span>
                  )}
                </Row>
              </div>
            </Col>
            <Col md={3}>
              <div className="main-form-section mt-3">
                <Row className="row justify-content-center mb-2">
                  <Form.Label>Category</Form.Label>
                  <Controller
                    name="category_id"
                    {...register("category_id", {
                      required: "Select Category",
                    })}
                    control={control}
                    render={({ field }) => (
                      <Select
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors.category_id
                              ? "red"
                              : baseStyles,
                          }),
                        }}
                        {...field}
                        options={categories}
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption.value); // Update Controller's value
                          GetAllSubCategory(selectedOption.value);
                          setValue("category_id", selectedOption);
                          setValue("sub_category_id", "");
                          setValue("child_category_id", "");
                        }}
                      />
                    )}
                  />
                  {errors.category_id && (
                    <span className="text-danger">
                      {errors.category_id.message}
                    </span>
                  )}
                </Row>
              </div>
            </Col>
            <Col md={3}>
              <div className="main-form-section mt-3">
                <Row className="row justify-content-center mb-2">
                  <Form.Label>Sub Category</Form.Label>
                  <Controller
                    name="sub_category_id" // name of the field
                    {...register("sub_category_id", {
                      // required: "Select Sub Category",
                    })}
                    control={control}
                    render={({ field }) => (
                      <Select
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors.sub_category_id
                              ? "red"
                              : baseStyles,
                          }),
                        }}
                        {...field}
                        options={subCategories}
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption.value); // Update Controller's value
                          GetAllChildSubCategory(selectedOption.value);
                          setValue("sub_category_id", selectedOption);
                          setValue("child_category_id", "");
                        }}
                      />
                    )}
                  />
                  {errors.sub_category_id && (
                    <span className="text-danger">
                      {errors.sub_category_id.message}
                    </span>
                  )}
                </Row>
              </div>
            </Col>
            <Col md={3}>
              <div className="main-form-section mt-3">
                <Row className="row justify-content-center mb-2">
                  <Form.Label>Child Category</Form.Label>
                  <Controller
                    name="child_category_id" // name of the field
                    {...register("child_category_id", {
                      //   required: "Select Child Category",
                    })}
                    control={control}
                    render={({ field }) => (
                      <Select
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors.child_category_id
                              ? "red"
                              : baseStyles,
                          }),
                        }}
                        {...field}
                        options={childCategories}
                      />
                    )}
                  />
                  {errors.child_category_id && (
                    <span className="text-danger">
                      {errors.child_category_id.message}
                    </span>
                  )}
                </Row>
              </div>
            </Col>
            <Col md={3}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>Name</Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        name="name"
                        placeholder="Name .."
                        className={classNames("", {
                          "is-invalid": errors?.name,
                        })}
                        {...register("name", {
                          required: "Name is required",
                        })}
                      />
                    </InputGroup>
                    {errors.name && (
                      <span className="text-danger">{errors.name.message}</span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>
            <Col md={3}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>Short Description</Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        name="short_description"
                        placeholder="Short Description"
                        maxLength={40}
                        className={classNames("", {
                          "is-invalid": errors?.short_description,
                        })}
                        {...register("short_description", {
                          required: "Short Description is required",
                        })}
                      />
                    </InputGroup>
                    {errors.short_description && (
                      <span className="text-danger">
                        {errors.short_description.message}
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>
            <Col md={3}>
              <div className="main-form-section mt-3">
                <Form.Label>Image</Form.Label>

                <Form.Group>
                  <Form.Control
                    className={classNames("", {
                      "is-invalid": errors?.image,
                    })}
                    type="file"
                    {...register("image", {
                      required:
                        typeof getValues("image") == "string"
                          ? false
                          : "image is required",
                      validate: async (value) => {
                        return await ValidationImage(
                          value,
                          ImageValidation.product.w,
                          ImageValidation.product.h
                        );
                      },
                    })}
                    accept="image/*"
                  />
                </Form.Group>
                {errors.image && (
                  <span className="text-danger">{errors.image.message}</span>
                )}
              </div>
            </Col>
            {/* <Col md={3}>
              <div className="main-form-section mt-3">
                <Form.Label>image</Form.Label>
                {imageFile && imageFile?.length > 0 && (
                  <div className="image-preview-container">
                    <img
                      // src={URL.createObjectURL(getValues("image")[0])}
                      src={URL?.createObjectURL(imageFile[0])}
                      alt="Preview"
                      className="image-preview"
                      style={{ width: "100px", height: "100px" }}
                    />
                  </div>
                )}
              </div>
            </Col> */}
            <Col lg={3}>
              <div className="main-form-section mt-3">
                <Form.Label>Image Preview</Form.Label>

                {typeof getValues("image") == "string" ? (
                  <div className="image-preview-container">
                    <img
                      src={IMG_URL + getValues("image")}
                      alt="Preview"
                      className="image-preview"
                      style={{ width: "150px", height: "130px" }}
                    />
                  </div>
                ) : (
                  imageFile &&
                  imageFile?.length > 0 && (
                    <div className="image-preview-container">
                      <img
                        // src={URL.createObjectURL(getValues("image")[0])}
                        src={URL?.createObjectURL(imageFile[0])}
                        alt="Preview"
                        className="image-preview"
                        style={{ width: "100px", height: "100px" }}
                      />
                    </div>
                  )
                )}
              </div>
            </Col>
            <Col md={3}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>Brand</Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        name="brand"
                        placeholder="Brand"
                        maxLength={40}
                        className={classNames("", {
                          "is-invalid": errors?.brand,
                        })}
                        {...register("brand", {
                          required: "Brand is required",
                        })}
                      />
                    </InputGroup>
                    {errors.brand && (
                      <span className="text-danger">
                        {errors.brand.message}
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>
            <Col md={3}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>Sold By</Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        name="sold_by"
                        placeholder="Sold By"
                        maxLength={40}
                        className={classNames("", {
                          "is-invalid": errors?.sold_by,
                        })}
                        {...register("sold_by", {
                          required: "Sold By is required",
                        })}
                      />
                    </InputGroup>
                    {errors.sold_by && (
                      <span className="text-danger">
                        {errors.sold_by.message}
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>
            <Col md={3}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>Matarial</Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        name="matarial"
                        placeholder="Matarial"
                        maxLength={40}
                        className={classNames("", {
                          "is-invalid": errors?.matarial,
                        })}
                        {...register("matarial", {
                          required: "Matarial is required",
                        })}
                      />
                    </InputGroup>
                    {errors.matarial && (
                      <span className="text-danger">
                        {errors.matarial.message}
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>
            <Col md={3}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>Free Delivery Days</Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="free_delivery_days"
                        placeholder="Free Delivery Days"
                        maxLength={40}
                        className={classNames("", {
                          "is-invalid": errors?.free_delivery_days,
                        })}
                        {...register("free_delivery_days", {
                          required: "Free Delivery Days is required",
                          pattern: {
                            value: /^[0-9][0-9]?$|^100$/,
                            message: "Please enter a number between 0 and 100",
                          },
                        })}
                      />
                    </InputGroup>
                    {errors.free_delivery_days && (
                      <span className="text-danger">
                        {errors.free_delivery_days.message}
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>
            <Col md={3}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>Fast Delivery Days</Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="fast_delivery_days"
                        placeholder="Fast Delivery Days"
                        maxLength={40}
                        className={classNames("", {
                          "is-invalid": errors?.fast_delivery_days,
                        })}
                        {...register("fast_delivery_days", {
                          required: "Fast Delivery Days is required",
                          pattern: {
                            value: /^[0-9][0-9]?$|^100$/,
                            message: "Please enter a number between 0 and 100",
                          },
                        })}
                      />
                    </InputGroup>
                    {errors.fast_delivery_days && (
                      <span className="text-danger">
                        {errors.fast_delivery_days.message}
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>
            <Col md={3}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>Extra Charges</Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="extra_charges"
                        placeholder="Extra Charges"
                        maxLength={40}
                        className={classNames("", {
                          "is-invalid": errors?.extra_charges,
                        })}
                        {...register("extra_charges", {
                          required: "Extra Charges is required",
                        })}
                      />
                    </InputGroup>
                    {errors.extra_charges && (
                      <span className="text-danger">
                        {errors.extra_charges.message}
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>
            <Col md={3}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>Tax Percentage</Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="tax_percentage"
                        placeholder="Tax Percentage"
                        maxLength={40}
                        className={classNames("", {
                          "is-invalid": errors?.tax_percentage,
                        })}
                        {...register("tax_percentage", {
                          required: "Tax Percentage is required",
                          pattern: {
                            value: /^[0-9][0-9]?$|^100$/,
                            message: "Please enter a number between 0 and 100",
                          },
                        })}
                      />
                    </InputGroup>
                    {errors.tax_percentage && (
                      <span className="text-danger">
                        {errors.tax_percentage.message}
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>
            <Col md={3}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>Box Unit</Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="box_unit"
                        placeholder="Box Unit"
                        maxLength={40}
                        className={classNames("", {
                          "is-invalid": errors?.box_unit,
                        })}
                        // {...register("box_unit", {
                        //   required: "Box Unit is required",
                        //   pattern: {
                        //     value: /^[0-9][0-9]?$|^100$/,
                        //     message: "Please enter a number between 0 and 100",
                        //   },
                        // })}
                        // {...register("box_unit", {
                        //   required: "Box Unit is required",
                        //   min: {
                        //     value: 1,
                        //     message: "Minimum value is 1",
                        //   },
                        //   pattern: {
                        //     value: /^[1-9][0-9]/,
                        //     message: "Please enter a valid number 1",
                        //   },
                        // })}

                        {...register("box_unit", {
                          required: "Box Unit is required",
                          min: {
                            value: 1,
                            message: "Minimum value is 1",
                          },
                          pattern: {
                            value: /^[1-9][0-9]*$/,
                            message: "Please enter a valid number",
                          },
                        })}
                      />
                    </InputGroup>
                    {errors.box_unit && (
                      <span className="text-danger">
                        {errors.box_unit.message}
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>
            <Col md={3}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>Minimum Order Quanitity</Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="min_quantity"
                        placeholder="Minimum Order Quanitity"
                        maxLength={40}
                        className={classNames("", {
                          "is-invalid": errors?.min_quantity,
                        })}
                        // {...register("min_quantity", {
                        //   required: "Minimum Order Quanitity is required",
                        //   pattern: {
                        //     value: /^[0-9][0-9]?$|^100$/,
                        //     message: "Please enter a number between 0 and 100",
                        //   },
                        // })}
                        {...register("min_quantity", {
                          required: "Minimum Order Quanitity is required",
                          min: {
                            value: 1,
                            message: "Minimum value is 1",
                          },
                          pattern: {
                            value: /^[1-9][0-9]*$/,
                            message: "Please enter a valid number",
                          },
                        })}
                      />
                    </InputGroup>
                    {errors.min_quantity && (
                      <span className="text-danger">
                        {errors.min_quantity.message}
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>
            <Col md={6}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>Generic Keywords</Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        name="generic_keywords"
                        placeholder="Generic Keywords"
                        maxLength={40}
                        className={classNames("", {
                          "is-invalid": errors?.generic_keywords,
                        })}
                        {...register("generic_keywords", {
                          required: "Generic Keywords is required",
                        })}
                      />
                    </InputGroup>
                    {errors.generic_keywords && (
                      <span className="text-danger">
                        {errors.generic_keywords.message}
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>
            {/* is COD */}
            <Col md={3}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>isCOD</Form.Label>

                  <Form.Group>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        {...register("is_cod", {})}
                      />
                    </div>
                  </Form.Group>
                </Row>
              </div>
            </Col>{" "}
            {/* is MOQ */}
            {/* <Col md={3}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>isMOQ</Form.Label>

                  <Form.Group>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        {...register("is_moq", {})}
                      />
                    </div>
                  </Form.Group>
                </Row>
              </div>
            </Col> */}
            {/* isReturnable */}
            <Col md={3}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>isReturnable</Form.Label>

                  <Form.Group>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        {...register("is_returnable", {})}
                      />
                    </div>
                  </Form.Group>
                </Row>
              </div>
            </Col>
            {/* isRefundable */}
            <Col md={3}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>isRefundable</Form.Label>

                  <Form.Group>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        {...register("is_refundable", {})}
                      />
                    </div>
                  </Form.Group>
                </Row>
              </div>
            </Col>
            {/* isCancellable */}
            <Col md={3}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-center">
                  <Form.Label>isCancellable</Form.Label>

                  <Form.Group>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        {...register("is_cancellable", {})}
                      />
                    </div>
                  </Form.Group>
                </Row>
              </div>
            </Col>
            <div className="main-form-section mt-5 "></div>
            <Card.Title>Product Price Details</Card.Title>
            <hr />
            <Col md={3}>
              <div className="main-form-section mt-3 me-3">
                <Row className="justify-content-center">
                  <Form.Label>
                    Stock
                    <span className="mandatory-star"> *</span>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id="tooltip-title">Stock</Tooltip>}
                    >
                      <FontAwesomeIcon
                        icon="fa-solid fa-circle-info"
                        className="ms-3"
                      />
                    </OverlayTrigger>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name={`stock`}
                    placeholder="Stock.."
                    className={classNames("", {
                      "is-invalid": errors.stock,
                    })}
                    {...register(`stock`, {
                      required: "Stock required",
                    })}
                  />
                </Row>
              </div>
            </Col>
            <Col md={3}>
              <div className="main-form-section mt-3 me-3">
                <Row className="justify-content-center">
                  <Form.Label> MRP</Form.Label>
                  <Form.Control
                    type="number"
                    name={`mrp`}
                    placeholder="MRP.."
                    className={classNames("", {
                      "is-invalid": errors.mrp,
                    })}
                    {...register(`mrp`, {
                      required: "MRP required",
                    })}
                    // onChange={(e) => {
                    //   if (
                    //     getValues("selling_price") + Number(platform_charges) >
                    //     Number(e.target.value)
                    //   ) {
                    //     setValue("selling_price", "");
                    //   }
                    // }}
                  />
                </Row>
              </div>
            </Col>
            <Col md={3}>
              <div className="main-form-section mt-3 me-3">
                <Row className="justify-content-center">
                  <Form.Label>B2B Selling Price</Form.Label>
                  <Form.Control
                    type="number"
                    name={`btb_price`}
                    placeholder="B2B Selling Price"
                    className={classNames("", {
                      "is-invalid": errors.btb_price,
                    })}
                    {...register(`btb_price`, {
                      required: "B2B Selling Price required",
                    })}
                    onChange={(e) => {
                      if (Number(e.target.value) > getValues("mrp")) {
                        setValue("btb_price", "");
                      } else {
                        setValue("btb_price", e.target.value);
                      }
                    }}
                  />
                </Row>
              </div>
            </Col>
            <Col md={3}>
              <div className="main-form-section mt-3 me-3">
                <Row className="justify-content-center">
                  <Form.Label>B2C Selling Price</Form.Label>
                  <Form.Control
                    type="number"
                    name={`btc_price`}
                    placeholder="B2C Selling Price"
                    className={classNames("", {
                      "is-invalid": errors.btc_price,
                    })}
                    {...register(`btc_price`, {
                      required: "B2C Selling Price required",
                    })}
                    onChange={(e) => {
                      if (Number(e.target.value) > getValues("mrp")) {
                        setValue("btc_price", "");
                      } else {
                        setValue("btc_price", e.target.value);
                      }
                    }}
                  />
                </Row>
              </div>
            </Col>
            <Col md={3}>
              <div className="main-form-section mt-3 me-3">
                <Row className="justify-content-center">
                  <Form.Label> SKU ID</Form.Label>
                  <Form.Control
                    type="text"
                    name={`sku_id`}
                    placeholder="SKU ID"
                    className={classNames("", {
                      "is-invalid": errors.sku_id,
                    })}
                    {...register(`sku_id`, {
                      required: "SKU ID required",
                    })}
                  />
                </Row>
              </div>
            </Col>
            <Col md={3}>
              <div className="main-form-section mt-3 me-3">
                <Row className="justify-content-center">
                  <Form.Label> HSN Code</Form.Label>
                  <Form.Control
                    type="text"
                    name={`hsn_code`}
                    placeholder="HSN Code"
                    className={classNames("", {
                      "is-invalid": errors.hsn_code,
                    })}
                    {...register(`hsn_code`, {
                      required: "HSN Code required",
                    })}
                  />
                </Row>
              </div>
            </Col>
            <Col md={3}>
              <div className="main-form-section mt-3 me-3">
                <Row className="justify-content-center">
                  <Form.Label> Size / Width (inches) </Form.Label>
                  <Form.Control
                    type="number"
                    name={`width`}
                    placeholder="Width"
                    className={classNames("", {
                      "is-invalid": errors.width,
                    })}
                    {...register(`width`, {
                      required: "Width required",
                      min: {
                        value: 0,
                        message: "Weight must be 0 or more",
                      },
                    })}
                  />
                </Row>
              </div>
            </Col>
            <Col md={3}>
              <div className="main-form-section mt-3 me-3">
                <Row className="justify-content-center">
                  <Form.Label> Length (meter)</Form.Label>
                  <Form.Control
                    type="number"
                    name={`length`}
                    placeholder="Length"
                    className={classNames("", {
                      "is-invalid": errors.length,
                    })}
                    {...register(`length`, {
                      required: "Length required",
                      min: {
                        value: 0,
                        message: "Weight must be 0 or more",
                      },
                    })}
                  />
                </Row>
              </div>
            </Col>
            <Col md={3}>
              <div className="main-form-section mt-3 me-3">
                <Row className="justify-content-center">
                  <Form.Label> Thickness (micron)</Form.Label>
                  <Form.Control
                    type="number"
                    name={`thickness`}
                    placeholder="Thickness"
                    className={classNames("", {
                      "is-invalid": errors.thickness,
                    })}
                    {...register(`thickness`, {
                      required: "Thickness required",
                      min: {
                        value: 0,
                        message: "Weight must be 0 or more",
                      },
                    })}
                  />
                </Row>
              </div>
            </Col>
            <Col md={3}>
              <div className="main-form-section mt-3 me-3">
                <Row className="justify-content-center">
                  <Form.Label> Color </Form.Label>
                  <Form.Control
                    type="text"
                    name={`color`}
                    placeholder="Color"
                    className={classNames("", {
                      "is-invalid": errors.color,
                    })}
                    {...register(`color`, {
                      required: "Color required",
                    })}
                  />
                </Row>
              </div>
            </Col>
            <Col md={3}>
              <div className="main-form-section mt-3 me-3">
                <Row className="justify-content-center">
                  <Form.Label> Weight (kg) </Form.Label>
                  <Form.Control
                    type="number"
                    name={`weight`}
                    placeholder="Weight"
                    className={classNames("", {
                      "is-invalid": errors.weight,
                    })}
                    {...register(`weight`, {
                      required: "Weight required",
                      min: {
                        value: 0,
                        message: "Weight must be 0 or more",
                      },
                    })}
                  />
                </Row>
              </div>
            </Col>
            <Col md={12} className="single-variant-tabsshd ">
              <div className="tab-content-data mt-4">
                <h6 className="proppdp">
                  Product Photos <span>(Max 8)</span>
                </h6>
                <div className="tab-content-data">
                  <div className="uploadbox">
                    <div className="row">
                      <div className="col-md-8">
                        <div className="slider-div">
                          <Swiper
                            slidesPerView={3.5}
                            spaceBetween={30}
                            freeMode={true}
                            pagination={{
                              clickable: false,
                            }}
                            modules={[FreeMode, Pagination]}
                            className="mySwiper"
                          >
                            {getValues("uploadimages")?.map((data, index) => (
                              <SwiperSlide key={index}>
                                <div className="upd-card">
                                  <label htmlFor={`product_image_${index}`}>
                                    {data.selectedImage ? (
                                      data.type == "image" ? (
                                        <img
                                          className="slide-img"
                                          src={data.selectedImage}
                                          onClick={() =>
                                            removeProductImage(index)
                                          }
                                        />
                                      ) : (
                                        <object
                                          className="slide-img"
                                          data={data.selectedImage}
                                          alt="Uploaded media"
                                          onClick={() =>
                                            removeProductImage(index)
                                          }
                                        />
                                      )
                                    ) : (
                                      <img
                                        className="slide-img"
                                        src={data.img}
                                      />
                                    )}
                                    {/* {data.selectedImage ? (
                                                      <object
                                                        className="slide-img"
                                                        data={
                                                          data.selectedImage
                                                        }
                                                        alt="Uploaded media"
                                                        onClick={() =>
                                                          removeProductImage(
                                                            index
                                                          )
                                                        }
                                                        // width="50"
                                                        // height="50"
                                                      />
                                                    ) : (
                                                      <img
                                                        className="slide-img"
                                                        src={data.img}
                                                        onClick={() =>
                                                          removeProductImage(
                                                            index
                                                          )
                                                        }
                                                      />
                                                    )} */}
                                  </label>

                                  <input
                                    type="file"
                                    className="d-none"
                                    accept="image/*,video/*"
                                    id={`product_image_${index}`}
                                    onChange={async (e) => {
                                      await handleProductImage(e, index);
                                    }}
                                  />

                                  {data?.selectedImage && (
                                    <>
                                      <img
                                        className="dltt-slide"
                                        onClick={() =>
                                          removeProductImage(index)
                                        }
                                        src={data.dltimg}
                                        alt="Delete icon"
                                      />
                                    </>
                                  )}
                                </div>
                              </SwiperSlide>
                            ))}
                          </Swiper>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="produuyct-discri">
                          <h6 className="tttt">Image Resolution</h6>
                          <p className="testtt">
                            Use clear color images with a ratio of 1:2. and max
                            500KB
                          </p>
                          <h6 className="tttt">Image Guidelines</h6>
                          <p className="testtt">
                            Upload authentic product photos taken in bright
                            lighting
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <div className="main-form-section mt-5"></div>
            <hr />
            <Col md={12}>
              <div className="main-form-section mt-3">
                <Row className="row justify-content-center mb-2">
                  <Form.Label>Product Features</Form.Label>
                  <Controller
                    name="product_features"
                    {...register("product_features", {
                      required: "Select Product Features",
                    })}
                    control={control}
                    render={({ field }) => (
                      <Select
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors.product_features
                              ? "red"
                              : baseStyles,
                          }),
                        }}
                        isMulti
                        {...field}
                        options={product_features}
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption.value);
                          setValue("product_features", selectedOption);
                        }}
                      />
                    )}
                  />
                  {errors.product_features && (
                    <span className="text-danger">
                      {errors.product_features.message}
                    </span>
                  )}
                </Row>
              </div>
            </Col>
            <Col md={12}>
              <div className="main-form-section mt-3">
                <Row className="row justify-content-center mb-2">
                  <Form.Label>Available Pincodes</Form.Label>
                  <Controller
                    name="available_pincodes"
                    {...register("available_pincodes", {
                      required: "Select Available Pincodes",
                    })}
                    control={control}
                    render={({ field }) => (
                      <Select
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors.available_pincodes
                              ? "red"
                              : baseStyles,
                          }),
                        }}
                        isMulti
                        {...field}
                        options={available_pincodes}
                        onInputChange={(e) => {
                          GetAllPincodes(e);
                        }}
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption.value);
                          setValue("available_pincodes", selectedOption);
                        }}
                      />
                    )}
                  />
                  {errors.available_pincodes && (
                    <span className="text-danger">
                      {errors.available_pincodes.message}
                    </span>
                  )}
                </Row>
              </div>
            </Col>
            <div className="main-form-section mt-5"></div>
            {getValues("product_type")?.value == "Bulk Order" && (
              <>
                <Card.Title>Product Minimum Order Quntity</Card.Title>
                <hr />
                {fields.map((variant, index) => (
                  <div key={variant.id} className="main-form-section mt-3">
                    <Row>
                      {" "}
                      <Col md={5}>
                        <div className="main-form-section mt-3">
                          <Row className="row justify-content-center mb-2">
                            <Form.Label>Quantity</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="number"
                                  placeholder={"Quantity"}
                                  min={0}
                                  // step="0.01"
                                  className={classNames("", {
                                    "is-invalid":
                                      errors?.product_min_order_qty?.[index]
                                        ?.quantity,
                                  })}
                                  name={`product_min_order_qty.${index}.quantity`}
                                  {...register(
                                    `product_min_order_qty.${index}.quantity`,
                                    {
                                      required: "Quantity is required",
                                      validate: (value) => {
                                        // if (!value) return "Discount is required";
                                        if (value && parseFloat(value) < 0) {
                                          return "Quantity is min 0 or greate";
                                        }
                                        return true; // Return true for valid input
                                      },
                                    }
                                  )}
                                />
                              </InputGroup>
                              {errors?.product_min_order_qty?.[index]
                                ?.quantity && (
                                <span className="text-danger">
                                  {
                                    errors?.product_min_order_qty?.[index]
                                      ?.quantity.message
                                  }
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>
                      <Col md={5}>
                        <div className="main-form-section mt-3">
                          <Row className="row justify-content-center mb-2">
                            <Form.Label>Percentage</Form.Label>
                            <Form.Group>
                              <InputGroup>
                                <Form.Control
                                  type="number"
                                  placeholder={"Percentage"}
                                  min={0}
                                  // step="0.01"
                                  className={classNames("", {
                                    "is-invalid":
                                      errors?.product_min_order_qty?.[index]
                                        ?.no,
                                  })}
                                  name={`product_min_order_qty.${index}.percentage`}
                                  // {...register(`product_min_order_qty.${index}.percentage`, {
                                  //   required: "Percentage is required",
                                  //   validate: (value) => {
                                  //     // if (!value) return "Discount is required";
                                  //     if (value && parseFloat(value) < 0) {
                                  //       return "Percentage is min 0 or greate";
                                  //     }
                                  //     return true; // Return true for valid input
                                  //   },
                                  // })}
                                  {...register(
                                    `product_min_order_qty.${index}.percentage`,
                                    {
                                      required: "Percentage is required",
                                      validate: (value) => {
                                        if (!value)
                                          return "Percentage is required";
                                        if (
                                          value &&
                                          (parseFloat(value) < 1 ||
                                            parseFloat(value) > 100)
                                        ) {
                                          return "Percentage must be between 1% and 100%";
                                        }
                                        return true; // Return true for valid input
                                      },
                                    }
                                  )}
                                />
                              </InputGroup>
                              {errors?.product_min_order_qty?.[index]
                                ?.percentage && (
                                <span className="text-danger">
                                  {
                                    errors?.product_min_order_qty?.[index]
                                      ?.percentage.message
                                  }
                                </span>
                              )}
                            </Form.Group>
                          </Row>
                        </div>
                      </Col>
                      <Col lg={2}>
                        <button
                          className="mt-3 add-varient"
                          type="button"
                          onClick={() => remove(index)}
                        >
                          Remove Items
                        </button>
                      </Col>
                    </Row>
                  </div>
                ))}
                <div className="main-form-section mt-3"></div>
                {/* <hr /> */}
                {errors?.product_min_order_qty && (
                  <span className="text-danger">
                    {errors?.product_min_order_qty.message}
                  </span>
                )}
                <div className="text-center">
                  <button
                    type="button"
                    className="add-varient"
                    onClick={() => {
                      clearErrors();
                      append({});
                    }}
                  >
                    + Add Items
                  </button>
                </div>{" "}
                <div className="main-form-section mt-3"></div>
                <hr />
                <div className="main-form-section mt-5"></div>
              </>
            )}
            <Card.Title>Product Coupon Details</Card.Title>
            <hr />
            <Col md={3}>
              <div className="main-form-section mt-3 me-3">
                <Row className="justify-content-center">
                  <Form.Label>Coupon Type </Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        as="select"
                        name="coupon_type"
                        className={classNames("", {
                          "is-invalid": errors?.coupon_type,
                        })}
                        {...register("coupon_type", {
                          required: "Coupon Type is required",
                        })}
                        onChange={(event) =>
                          setSelectedType(event.target.value)
                        }
                      >
                        <option value="">Select Type</option>
                        <option value={product_coupon.Percentage}>
                          {product_coupon.Percentage}
                        </option>
                        <option value={product_coupon.FixedAmount}>
                          {product_coupon.FixedAmount}
                        </option>{" "}
                        {/* <option value="combo">Combo</option> */}
                      </Form.Control>
                    </InputGroup>
                    {errors.coupon_type && (
                      <span className="text-danger">
                        {errors.coupon_type.message}
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>
            <Col md={3}>
              <div className="main-form-section mt-3 me-3">
                <Row className="justify-content-center">
                  <Form.Label>Coupon Name</Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        name="coupon_name"
                        placeholder="Coupon Name"
                        className={classNames("", {
                          "is-invalid": errors?.coupon_name,
                        })}
                        {...register("coupon_name", {
                          required: "Coupon Name is required",
                        })}
                      />
                    </InputGroup>
                    {errors.coupon_name && (
                      <span className="text-danger">
                        {errors.coupon_name.message}
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>
            <Col md={3}>
              <div className="main-form-section mt-3 me-3">
                <Row className="justify-content-center">
                  <Form.Label>Coupon Code </Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        name="coupon_code"
                        placeholder="Coupon Code .."
                        className={classNames("", {
                          "is-invalid": errors?.coupon_code,
                        })}
                        {...register("coupon_code", {
                          required: "Coupon Code is required",
                        })}
                      />
                    </InputGroup>
                    {errors.coupon_code && (
                      <span className="text-danger">
                        {errors.coupon_code.message}
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>
            <Col md={3}>
              <div className="main-form-section mt-3 me-3">
                <Row className="justify-content-center">
                  <Form.Label>
                    {" "}
                    {selectedType === ""
                      ? product_coupon.Percentage
                      : selectedType}
                  </Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="discount"
                        placeholder={
                          selectedType === ""
                            ? product_coupon.Percentage
                            : selectedType
                        }
                        max={100}
                        min={0}
                        step="0.01"
                        className={classNames("", {
                          "is-invalid": errors?.discount,
                        })}
                        {...register("discount", {
                          required: "Discount is required",
                          validate: (value) => {
                            if (!value) return "Discount is required";
                            if (selectedType === product_coupon.Percentage) {
                              // Validate percentage discount
                              if (
                                parseFloat(value) < 1 ||
                                parseFloat(value) > 100
                              ) {
                                return "Discount must be between 1% and 100%";
                              }
                            } else {
                              const minimumOrderValue = parseFloat(
                                getValues("minimum_order")
                              );
                              if (parseFloat(value) <= 0) {
                                return "Discount must be a positive number";
                              }

                              // Check if discount is less than minimum order value
                              if (parseFloat(value) >= minimumOrderValue) {
                                return "Discount must be less than the minimum order value";
                              }
                            }
                            return true; // Return true for valid input
                          },
                        })}
                      />
                    </InputGroup>
                    {errors.discount && (
                      <span className="text-danger">
                        {errors.discount.message}
                      </span>
                    )}
                  </Form.Group>
                </Row>
              </div>
            </Col>
            {/* <div className="main-form-section mt-5 "></div>
            <hr /> */}
            <div className="main-form-section mt-5 "></div>
            <Card.Title>Product Details</Card.Title>
            <hr />
            <Col lg={12}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-start">
                  {" "}
                  <Col sm={12}>
                    <Form.Label className="text-center">About</Form.Label>
                    <Form.Group>
                      <Controller
                        name="about" // Provide the field name
                        control={control} // Pass the control object from useForm()
                        rules={{
                          required: "About is required",
                        }} // Validation rules
                        defaultValue={`<ul>
    <li>CMJ Tape Made in India is Ideal for Packing E-Commerce Boxes, Packing Cartons, Export Packaging, and Retail Packing and can be used for Arts &amp; Crafts work or for as Office Stationery Product, Quality Micron adhesive tape, works best for arts, stationary, protecting labels from water damage, kid's school projects and other crafty activities.</li>
    <li>CMJ BOPP tapes being thermoplastic polymer works in both the extreme temperatures that mean at low as well as high-temperature ranges. The adhesives used commonly are hot melt synthetic rubber as it seals quickly, reliable and consistent. These adhesives bonds quickly to the surface with additional properties like UV, shear and heat resistant. The outstanding features which complement the tapes are</li>
    <li>CMJ Polypropylene film can be stretched in both the directions thus mentioned as biaxial oriented. This stretching of the film increases the strength and clarity/transparency of the film. High tensile strength and rugged nature making it ideal to use for packaging and labeling.</li>
    <li>CMJ Adhesive packing tapes that are used in sealing medium to heavy-duty carton sealing, shipping, and inventory management and in logistics industries are actually BOPP tapes.</li>
    <li>CMJ Polypropylene has several other properties like resistant to abrasion, chemically reacting agents, burst and moisture. The surface of the film is easy to print and coat, which makes it useful for custom printed BOPP packing tapes. The tape can be easily slitting when required</li><br>
</ul>`}
                        render={({ field }) => (
                          <JoditEditor
                            value={field.value}
                            onChange={(newContent) =>
                              field.onChange(newContent)
                            }
                            onBlur={field.onBlur}
                            className={classNames("", {
                              "is-invalid": !!errors.about,
                            })}
                            placeholder="About"
                          />
                        )}
                      />
                    </Form.Group>
                    {errors.about && (
                      <span className="text-danger">
                        {errors.about.message}
                      </span>
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg={12}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-start">
                  <Col sm={12}>
                    <Form.Label className="text-center">
                      Product Info
                    </Form.Label>
                    <Form.Group>
                      <Controller
                        name="product_info" // Provide the field name
                        control={control} // Pass the control object from useForm()
                        rules={{
                          required: "Product Info is required",
                        }} // Validation rules
                        defaultValue={`<div class="mt-5 row">
    <div class="col-xxl-6 col-xl-6 col-lg-12 col-md-12">
        <p class="text-p">Technical Details</p>
        <div class="product-table">
            <table class="tableDdemo table">
                <tbody>
                    <tr class="text-p">
                        <td>Brand</td>
                        <td>CMJ INDUSTRIES</td>
                    </tr>
                    <tr class="text-p">
                        <td>Colour</td>
                        <td>Transparent Tape</td>
                    </tr>
                    <tr class="text-p">
                        <td>Material</td>
                        <td>Biaxially Oriented Polypropylene</td>
                    </tr>
                    <tr class="text-p">
                        <td>Number of Items</td>
                        <td>4</td>
                    </tr>
                    <tr class="text-p">
                        <td>Recommended Uses For Product</td>
                        <td>Packaging</td>
                    </tr>
                    <tr class="text-p">
                        <td>Special Feature</td>
                        <td>Heat,Transparent</td>
                    </tr>
                    <tr class="text-p">
                        <td>Surface Recommendation</td>
                        <td>Cardboard</td>
                    </tr>
                    <tr class="text-p">
                        <td>Size</td>
                        <td>3 Inch 100 Meter Pack of 04</td>
                    </tr>
                    <tr class="text-p">
                        <td>Compatible Material</td>
                        <td>Industrial, Packaging, Bopp</td>
                    </tr>
                    <tr class="text-p">
                        <td>Manufacturer</td>
                        <td>CMJ Industries Pune, Manufacturer All Types of Self Adhesive Bopp Tapes, Headquarters / Factory CMJ INDUSTRIES Gat No 1582, Bhangre Complex, Shop No-13, Near Swami Hotel, Chikhali Pune-411062 Landmark :-Near CMJ Tower Manufacturer All Types of Self Adhesive Bopp Tapes, Transparent Tape, Brown Tape, Packaging Tape. Customer Care :- cmjindustries13@gmail.com Support Email:- cmjsupport@gmail.com HelpLine:-8806 5880 6500 24/7 Customer support</td>
                    </tr>
                    <tr class="text-p">
                        <td>Country of Origin</td>
                        <td>India</td>
                    </tr>
                    <tr class="text-p">
                        <td>Model number</td>
                        <td>TRN3" 100 Meter Pack of 04</td>
                    </tr>
                    <tr class="text-p">
                        <td>Item Weight</td>
                        <td>170 g</td>
                    </tr>
                    <tr class="text-p">
                        <td>Product Dimensions</td>
                        <td>7 x 7 x 7 cm; 170 g</td>
                    </tr>
                    <tr class="text-p">
                        <td>Item model number</td>
                        <td>TRN3" 100 Meter Pack of 04</td>
                    </tr>
                    <tr class="text-p">
                        <td>Item Heigh</td>
                        <td>7 Centimeters</td>
                    </tr>
                    <tr class="text-p">
                        <td>Item Width</td>
                        <td>7 Centimeters</td>
                    </tr>
                    <tr class="text-p">
                        <td>Included Components</td>
                        <td>Self-Adhesive Bopp Packaging Tape</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="col-xxl-6 col-xl-6 col-lg-12 col-md-12">
        <p class="text-p">Additional Information</p>
        <table class="tableDdemo mt-3 table">
            <tbody>
                <tr class="text-p">
                    <td>ASIN</td>
                    <td>B0BSZY4X38</td>
                </tr>

                <tr class="text-p">
                    <td>Best Sellers Rank</td>
                    <td>#22,879 in Industrial &amp; Scientific (See Top 100 in Industrial &amp; Scientific)<br>#2,018 in Packaging &amp; Shipping Supplies<br>#2,594 in Tape</td>
                </tr>
                <tr class="text-p">
                    <td>Date First Available</td>
                    <td>23 January 2023</td>
                </tr>
                <tr class="text-p">
                    <td>Manufacturer</td>
                    <td>CMJ Industries Pune, Manufacturer All Types of Self Adhesive Bopp Tapes, Headquarters / Factory CMJ INDUSTRIES Gat No 1582, Bhangre Complex, Shop No-13, Near Swami Hotel, Chikhali Pune-411062 Landmark :-Near CMJ Tower Manufacturer All Types of Self Adhesive Bopp Tapes, Transparent Tape, Brown Tape, Packaging Tape. Customer Care :- cmjindustries13@gmail.com Support Email:- cmjsupport@gmail.com HelpLine:-8806 5880 6500 24/7 Customer support</td>
                </tr>
                <tr class="text-p">
                    <td>Packer</td>
                    <td>Headquarters / Factory CMJ INDUSTRIES Gat No 1582, Bhangre Complex, Shop No-13, Near Swami Hotel, Chikhali Pune-411062 Landmark :-Near CMJ Tower Manufacturer All Types of Self Adhesive Bopp Tapes, Transparent Tape, Brown Tape, Packaging Tape. Customer Care :- cmjindustries13@gmail.com Support Email:- cmjsupport@gmail.com HelpLine:-8806 5880 6500 24/7 Customer support</td>
                </tr>
                <tr class="text-p">
                    <td>Importer</td>
                    <td>Headquarters / Factory CMJ INDUSTRIES Gat No 1582, Bhangre Complex, Shop No-13, Near Swami Hotel, Chikhali Pune-411062 Landmark :-Near CMJ Tower Manufacturer All Types of Self Adhesive Bopp Tapes, Transparent Tape, Brown Tape, Packaging Tape. Customer Care :- cmjindustries13@gmail.com Support Email:- cmjsupport@gmail.com HelpLine:-8806 5880 6500 24/7 Customer support</td>
                </tr>
                <tr class="text-p">
                    <td>Item Dimensions LxWxH</td>
                    <td>7 x 7 x 7 Centimeters</td>
                </tr>
                <tr class="text-p">
                    <td>Net Quantity</td>
                    <td>400.00 Meters</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>`}
                        render={({ field }) => (
                          <JoditEditor
                            value={field.value}
                            onChange={(newContent) =>
                              field.onChange(newContent)
                            }
                            onBlur={field.onBlur}
                            className={classNames("", {
                              "is-invalid": !!errors.product_info,
                            })}
                            placeholder="product_info"
                          />
                        )}
                      />
                    </Form.Group>
                    {errors.product_info && (
                      <span className="text-danger">
                        {errors.product_info.message}
                      </span>
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg={12}>
              <div className="main-form-section mt-3">
                <Row className="justify-content-start">
                  <Col sm={12}>
                    <Form.Label className="text-center">
                      Product Description
                    </Form.Label>
                    <Form.Group>
                      <Controller
                        name="product_description" // Provide the field name
                        control={control} // Pass the control object from useForm()
                        rules={{
                          required: "Product Description is required",
                        }} // Validation rules
                        defaultValue={`<div class="celwidget aplus-module 3p-module-b aplus-standard" cel_widget_id="aplus-3p-module-b" data-csa-c-id="vi2co8-skkif7-1lcuuf-fex848" data-cel-widget="aplus-3p-module-b" style="box-sizing: border-box; color: rgb(15, 17, 17); font-family: &quot;Amazon Ember&quot;, Arial, sans-serif; font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">
    <div class="aplus-module-wrapper aplus-3p-fixed-width" style="box-sizing: border-box; width: 970px; margin-left: auto; margin-right: auto;">
        <h3 class="a-spacing-mini" style="box-sizing: border-box; padding: 0px 0px 4px; margin-top: 0px; margin-right: 0px; margin-bottom: 4px !important; margin-left: 0px; text-rendering: optimizelegibility; font-weight: 700; font-size: 18px; line-height: 24px;">Welcome To "CMJ INDUSTRIES". CMJ Industries is Big Brand. Pune Maharashtra Based, Since 2019.</h3><img alt="CMJ Banner 31 Aug" src="https://m.media-amazon.com/images/S/aplus-media-library-service-media/de2444d0-7ca6-4a18-9ec2-fd2928eb466d.__CR0,197,3000,1856_PT0_SX970_V1___.jpg" class="a-spacing-base" data-src="https://m.media-amazon.com/images/S/aplus-media-library-service-media/de2444d0-7ca6-4a18-9ec2-fd2928eb466d.__CR0,197,3000,1856_PT0_SX970_V1___.jpg" style="box-sizing: border-box; max-width: 100%; border: 0px; vertical-align: top; margin-bottom: 12px !important;">
        <h3 class="a-spacing-mini" style="box-sizing: border-box; padding: 0px 0px 4px; margin-top: 0px; margin-right: 0px; margin-bottom: 4px !important; margin-left: 0px; text-rendering: optimizelegibility; font-weight: 700; font-size: 18px; line-height: 24px;">CMJ INDUSTRIES is BIG BRAND PUNE MAHARASHTRA BASED Since 2019. (Cello Tape means CMJ INDUSTRIES)</h3>
        <p class="a-spacing-base" style="box-sizing: border-box; padding: 0px; margin-top: 0px; margin-right: 0px; margin-bottom: 12px !important; margin-left: 0px;">Dear Customer Welcome<span class="a-text-bold" style="box-sizing: border-box; font-weight: 700 !important;"><span>&nbsp;</span>To CMJ INDUSTRIES</span></p>
        <p class="a-spacing-base" style="box-sizing: border-box; padding: 0px; margin-top: -4px; margin-right: 0px; margin-bottom: 12px !important; margin-left: 0px;">All range Available.</p>
        <p class="a-spacing-base" style="box-sizing: border-box; padding: 0px; margin-top: -4px; margin-right: 0px; margin-bottom: 12px !important; margin-left: 0px;"><span class="a-text-bold" style="box-sizing: border-box; font-weight: 700 !important;">Transparent Tape Available, Brown Tape Available.</span></p>
        <p class="a-spacing-base" style="box-sizing: border-box; padding: 0px; margin-top: -4px; margin-right: 0px; margin-bottom: 12px !important; margin-left: 0px;">1 Inch, 2 Inch and 3 Inch Size Available always in stock.</p>
        <p class="a-spacing-base" style="box-sizing: border-box; padding: 0px; margin-top: -4px; margin-right: 0px; margin-bottom: 12px !important; margin-left: 0px;"><span class="a-text-bold" style="box-sizing: border-box; font-weight: 700 !important;"><u style="box-sizing: border-box;">You will get here ACCURATE LENGTH. We never comprise with Length or Quality. CMJ INDUSTRIES IS BIG BRAND.</u></span></p>
        <p class="a-spacing-base" style="box-sizing: border-box; padding: 0px; margin-top: -4px; margin-right: 0px; margin-bottom: 12px !important; margin-left: 0px;"><span class="a-text-bold" style="box-sizing: border-box; font-weight: 700 !important;"><u style="box-sizing: border-box;">for more details you can visit our BRAND STORE CMJ INDUSTRIES.</u></span></p>
        <p class="a-spacing-base" style="box-sizing: border-box; padding: 0px; margin-top: -4px; margin-right: 0px; margin-bottom: 12px !important; margin-left: 0px;"><span class="a-text-bold" style="box-sizing: border-box; font-weight: 700 !important;"><u style="box-sizing: border-box;">30 MTR, 40 MTR, 65 MTR, 100 MTR Length always in stock.</u></span></p>
        <p class="a-spacing-base" style="box-sizing: border-box; padding: 0px; margin-top: -4px; margin-right: 0px; margin-bottom: 12px !important; margin-left: 0px;"><span class="a-text-bold" style="box-sizing: border-box; font-weight: 700 !important;"><span class="a-text-italic" style="box-sizing: border-box; font-style: italic !important;">Keep In mind Always our business slogan. Choose Best- Stick Best, First use then Trust.</span></span></p>
        <p class="a-spacing-base" style="box-sizing: border-box; padding: 0px; margin: -4px 0px 0px;"><span class="a-text-bold" style="box-sizing: border-box; font-weight: 700 !important;"><span class="a-text-italic" style="box-sizing: border-box; font-style: italic !important;">Thanks &amp; Warm Regards.</span></span></p>
    </div>
</div>
<div class="celwidget aplus-module 3p-module-b aplus-standard" cel_widget_id="aplus-3p-module-b" data-csa-c-id="b7vrxw-8ifmyl-h1kdg7-2xrwia" data-cel-widget="aplus-3p-module-b" style="box-sizing: border-box; color: rgb(15, 17, 17); font-family: &quot;Amazon Ember&quot;, Arial, sans-serif; font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">
    <div class="aplus-module-wrapper aplus-3p-fixed-width" style="box-sizing: border-box; width: 970px; margin-left: auto; margin-right: auto;">
        <h3 class="a-spacing-mini" style="box-sizing: border-box; padding: 0px 0px 4px; margin-top: 0px; margin-right: 0px; margin-bottom: 4px !important; margin-left: 0px; text-rendering: optimizelegibility; font-weight: 700; font-size: 18px; line-height: 24px;">Transparent Tape</h3><img alt="trn1" src="https://m.media-amazon.com/images/S/aplus-media-library-service-media/42d14009-165c-4a57-a33f-af2be1140e7f.__CR0,0,970,600_PT0_SX970_V1___.jpg" class="a-spacing-base" data-src="https://m.media-amazon.com/images/S/aplus-media-library-service-media/42d14009-165c-4a57-a33f-af2be1140e7f.__CR0,0,970,600_PT0_SX970_V1___.jpg" style="box-sizing: border-box; max-width: 100%; border: 0px; vertical-align: top; margin-bottom: 12px !important;">
    </div>
</div>
<div class="celwidget aplus-module 3p-module-b aplus-standard" cel_widget_id="aplus-3p-module-b" data-csa-c-id="n10laj-2t0sv-2m358q-huv69a" data-cel-widget="aplus-3p-module-b" style="box-sizing: border-box; color: rgb(15, 17, 17); font-family: &quot;Amazon Ember&quot;, Arial, sans-serif; font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">
    <div class="aplus-module-wrapper aplus-3p-fixed-width" style="box-sizing: border-box; width: 970px; margin-left: auto; margin-right: auto;">
        <h3 class="a-spacing-mini" style="box-sizing: border-box; padding: 0px 0px 4px; margin-top: 0px; margin-right: 0px; margin-bottom: 4px !important; margin-left: 0px; text-rendering: optimizelegibility; font-weight: 700; font-size: 18px; line-height: 24px;">Brown Tape</h3><img alt="brn 2" src="https://m.media-amazon.com/images/S/aplus-media-library-service-media/2ad87aa2-8059-455a-9f65-35b663228a33.__CR0,0,970,600_PT0_SX970_V1___.jpg" class="a-spacing-base" data-src="https://m.media-amazon.com/images/S/aplus-media-library-service-media/2ad87aa2-8059-455a-9f65-35b663228a33.__CR0,0,970,600_PT0_SX970_V1___.jpg" style="box-sizing: border-box; max-width: 100%; border: 0px; vertical-align: top; margin-bottom: 12px !important;">
    </div>
</div>
<div class="celwidget aplus-module 3p-module-b aplus-standard" cel_widget_id="aplus-3p-module-b" data-csa-c-id="ibk36g-8ws9qc-rhlhq5-hskdmu" data-cel-widget="aplus-3p-module-b" style="box-sizing: border-box; color: rgb(15, 17, 17); font-family: &quot;Amazon Ember&quot;, Arial, sans-serif; font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">
    <div class="aplus-module-wrapper aplus-3p-fixed-width" style="box-sizing: border-box; width: 970px; margin-left: auto; margin-right: auto;">
        <h3 class="a-spacing-mini" style="box-sizing: border-box; padding: 0px 0px 4px; margin-top: 0px; margin-right: 0px; margin-bottom: 4px !important; margin-left: 0px; text-rendering: optimizelegibility; font-weight: 700; font-size: 18px; line-height: 24px;">Why us?</h3><img alt="cmj transparent tape 2 4 5" src="https://m.media-amazon.com/images/S/aplus-media-library-service-media/5acfc7ec-674b-43d8-b1d1-7b23dcea87be.__CR0,0,970,600_PT0_SX970_V1___.jpg" class="a-spacing-base" data-src="https://m.media-amazon.com/images/S/aplus-media-library-service-media/5acfc7ec-674b-43d8-b1d1-7b23dcea87be.__CR0,0,970,600_PT0_SX970_V1___.jpg" style="box-sizing: border-box; max-width: 100%; border: 0px; vertical-align: top; margin-bottom: 12px !important;">
    </div>
</div>
<div class="celwidget aplus-module 3p-module-b aplus-standard" cel_widget_id="aplus-3p-module-b" data-csa-c-id="h3v5fg-rdhoov-q8efwc-rqlglb" data-cel-widget="aplus-3p-module-b" style="box-sizing: border-box; color: rgb(15, 17, 17); font-family: &quot;Amazon Ember&quot;, Arial, sans-serif; font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">
    <div class="aplus-module-wrapper aplus-3p-fixed-width" style="box-sizing: border-box; width: 970px; margin-left: auto; margin-right: auto;">
        <h3 class="a-spacing-mini" style="box-sizing: border-box; padding: 0px 0px 4px; margin-top: 0px; margin-right: 0px; margin-bottom: 4px !important; margin-left: 0px; text-rendering: optimizelegibility; font-weight: 700; font-size: 18px; line-height: 24px;">Why us?</h3><img alt="cmj brown tape 2 4 5" src="https://m.media-amazon.com/images/S/aplus-media-library-service-media/5bc8ad26-e110-4499-8e81-4a760f68de34.__CR0,0,970,600_PT0_SX970_V1___.jpg" class="a-spacing-base" data-src="https://m.media-amazon.com/images/S/aplus-media-library-service-media/5bc8ad26-e110-4499-8e81-4a760f68de34.__CR0,0,970,600_PT0_SX970_V1___.jpg" style="box-sizing: border-box; max-width: 100%; border: 0px; vertical-align: top; margin-bottom: 12px !important;">
    </div>
</div>
<div class="celwidget aplus-module 3p-module-b aplus-standard" cel_widget_id="aplus-3p-module-b" data-csa-c-id="dwjbq6-mjjmkj-w5pvby-l4b49x" data-cel-widget="aplus-3p-module-b" style="box-sizing: border-box; color: rgb(15, 17, 17); font-family: &quot;Amazon Ember&quot;, Arial, sans-serif; font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">
    <div class="aplus-module-wrapper aplus-3p-fixed-width" style="box-sizing: border-box; width: 970px; margin-left: auto; margin-right: auto;">
        <h3 class="a-spacing-mini" style="box-sizing: border-box; padding: 0px 0px 4px; margin-top: 0px; margin-right: 0px; margin-bottom: 4px !important; margin-left: 0px; text-rendering: optimizelegibility; font-weight: 700; font-size: 18px; line-height: 24px;">Superb Adhesive Quality</h3><img alt="strong tape" src="https://m.media-amazon.com/images/S/aplus-media-library-service-media/1cd924b4-8cd3-4b9c-b8dd-2e5bcddb72c4.__CR0,0,970,600_PT0_SX970_V1___.jpg" class="a-spacing-base" data-src="https://m.media-amazon.com/images/S/aplus-media-library-service-media/1cd924b4-8cd3-4b9c-b8dd-2e5bcddb72c4.__CR0,0,970,600_PT0_SX970_V1___.jpg" style="box-sizing: border-box; max-width: 100%; border: 0px; vertical-align: top; margin-bottom: 12px !important;">
    </div>
</div>
<div class="celwidget aplus-module 3p-module-b aplus-standard" cel_widget_id="aplus-3p-module-b" data-csa-c-id="f1wye8-6iqjff-988z5s-mqmaj2" data-cel-widget="aplus-3p-module-b" style="box-sizing: border-box; color: rgb(15, 17, 17); font-family: &quot;Amazon Ember&quot;, Arial, sans-serif; font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;">
    <div class="aplus-module-wrapper aplus-3p-fixed-width" style="box-sizing: border-box; width: 970px; margin-left: auto; margin-right: auto;">
        <h3 class="a-spacing-mini" style="box-sizing: border-box; padding: 0px 0px 4px; margin-top: 0px; margin-right: 0px; margin-bottom: 4px !important; margin-left: 0px; text-rendering: optimizelegibility; font-weight: 700; font-size: 18px; line-height: 24px;">Best for Packaging</h3><img alt="best tape 2 " src="https://m.media-amazon.com/images/S/aplus-media-library-service-media/2073b149-85c4-4bb5-b103-f9078bf116fe.__CR0,0,970,600_PT0_SX970_V1___.jpg" class="a-spacing-base" data-src="https://m.media-amazon.com/images/S/aplus-media-library-service-media/2073b149-85c4-4bb5-b103-f9078bf116fe.__CR0,0,970,600_PT0_SX970_V1___.jpg" style="box-sizing: border-box; max-width: 100%; border: 0px; vertical-align: top; margin-bottom: 12px !important;">
    </div>
</div>`}
                        render={({ field }) => (
                          <JoditEditor
                            value={field.value}
                            onChange={(newContent) =>
                              field.onChange(newContent)
                            }
                            onBlur={field.onBlur}
                            className={classNames("", {
                              "is-invalid": !!errors.product_description,
                            })}
                            placeholder="product_description"
                          />
                        )}
                      />
                    </Form.Group>
                    {errors.product_description && (
                      <span className="text-danger">
                        {errors.product_description.message}
                      </span>
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
            {/* <div className="main-form-section mt-5"></div>
            <Card.Title>
              Product Quantity Details
              {quantityError && (
                <div className="text-danger">{quantityError}</div>
              )}
            </Card.Title>
            <hr />

            {fields.map((variant, index) => (
              <div key={variant.id} className="main-form-section mt-3">
                <Row>
                  <Col md={3}>
                    <div className="main-form-section mt-3">
                      <Row className="justify-content-center">
                        <Form.Label>Quantity</Form.Label>
                        <Form.Group>
                          <InputGroup>
                            <Form.Control
                              type="number"
                              name={`quantity.${index}.quantity`}
                              placeholder="Quantity"
                              className={classNames("", {
                                "is-invalid":
                                  errors?.quantity?.[index]?.quantity, // Updated error handling
                              })}
                              {...register(`quantity.${index}.quantity`, {
                                required: "Quantity is required",
                              })}
                            />
                          </InputGroup>
                          {errors?.quantity?.[index]?.quantity && (
                            <span className="text-danger">
                              {errors?.quantity?.[index]?.quantity.message}
                            </span>
                          )}
                        </Form.Group>
                      </Row>
                    </div>
                  </Col>

                  <Col md={3}>
                    <div className="main-form-section mt-3">
                      <Row className="row justify-content-center mb-2">
                        <Form.Label>Unit</Form.Label>
                        <Controller
                          name={`quantity.${index}.unit_id`}
                          {...register(`quantity.${index}.unit_id`, {
                            required: "Select Unit",
                          })}
                          control={control}
                          render={({ field }) => (
                            <Select
                              styles={{
                                control: (baseStyles) => ({
                                  ...baseStyles,
                                  borderColor: errors?.quantity?.[index]
                                    ?.unit_id
                                    ? "red"
                                    : baseStyles,
                                }),
                              }}
                              {...field}
                              options={units}
                              onChange={(selectedOption) => {
                                field.onChange(selectedOption.value); // Update Controller's value

                                setValue(
                                  `quantity.${index}.unit_id`,
                                  selectedOption
                                );
                              }}
                            />
                          )}
                        />
                        {errors?.quantity?.[index]?.unit_id && (
                          <span className="text-danger">
                            {errors?.quantity?.[index]?.unit_id.message}
                          </span>
                        )}
                      </Row>
                    </div>
                  </Col>

                 
                  <Col md={3}>
                    <div className="main-form-section mt-3">
                      <Row className="justify-content-center">
                        <Form.Label>Price</Form.Label>
                        <Form.Group>
                          <InputGroup>
                            <Form.Control
                              type="number"
                              name={`quantity.${index}.price`}
                              placeholder="Price"
                              className={classNames("", {
                                "is-invalid": errors?.quantity?.[index]?.price, // Updated error handling
                              })}
                              {...register(`quantity.${index}.price`, {
                                required: "Price is required",
                              })}
                            />
                          </InputGroup>
                          {errors?.quantity?.[index]?.price && (
                            <span className="text-danger">
                              {errors.quantity[index].price.message}
                            </span>
                          )}
                        </Form.Group>
                      </Row>
                    </div>
                  </Col>

           
                  <Col lg={3}>
                    <button
                      className="mt-3 add-varient"
                      type="button"
                      onClick={() => remove(index)}
                    >
                      Remove Quantity
                    </button>
                  </Col>
                </Row>
              </div>
            ))} */}
            {/* <div className="main-form-section mt-3"></div>
            <hr />
            <div className="text-center">
              <button
                type="button"
                className="add-varient"
                onClick={() => append({})}
              >
                + Add Quantity
              </button>
            </div> */}
            <Row className="mt-5 pb-3">
              <div className="d-flex justify-content-center">
                <Link>
                  <CancelButton
                    name={"cancel"}
                    handleClose={props.handleClose}
                  />
                </Link>

                <Button
                  name={"save"}
                  onClick={handleSubmit(onSubmit)}
                  type="button"
                  className="save-btnnnnnn me-2"
                >
                  <FontAwesomeIcon
                    icon="fa-solid fa-floppy-disk"
                    className="me-2"
                  />
                  save
                </Button>

                {/* <Button
                  name={"next"}
                  type="button"
                  className="save-btnnnnnn me-2"
                  onClick={handleNext}
                >
                  <FontAwesomeIcon
                    icon="fa-solid fa-arrow-right"
                    className="me-2"
                  />
                  Next
                </Button> */}
              </div>
            </Row>
          </Row>
        </Form>
      </fieldset>

      {/* <Row className="mt-5 pb-3">
                        <div className="d-flex justify-content-center">
                          <Link>
                            <CancelButton
                              name={"cancel"}
                              onClick={handlePrevious}
                            />
                          </Link>

                          

                          <Button
                            name={"next"}
                            type="button"
                            className="save-btnnnnnn me-2"
                            onClick={handleNext}
                          >
                            <FontAwesomeIcon
                              icon="fa-solid fa-floppy-disk"
                              className="me-2"
                             
                            />
                            Next
                          </Button>
                        </div>
                      </Row> */}

      <Successfull_Modal
        show={successModal}
        message={"Sucessfully"}
        subMessage={"Product And Details Added Sucessfully"}
        onHide={() => setSuccessModal(false)}
      />
    </>
  );
}

export default Step1;
